import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Modal, Popover, Select } from "antd";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

import PrimaryButton from "components/ui/button/PrimaryButtton";
import { topUpAction } from "redux/top-up/top-up.slice";
import FullPageSpiner from "components/ui/spiner/FullPageSpiner";
import SecondaryButton from "components/ui/button/SecondaryButton";
import { LuEdit2 } from "react-icons/lu";
import { paymentManagerAction } from "redux/payment-manager/payment-manager.slice";
import { sendLogs } from "common/function";
import { TypeAction, TypeGroup } from "common/constant";

type PropsType = {
  buttonStyle?: string;
  buttonIcon?: ReactElement<HTMLElement>;
  onSubmit?: Function;
  info?: any;
  handleSearch?: any;
  userInfo?: any;
};

const PaymentEditModal = (props: PropsType) => {
  const { info, handleSearch, userInfo } = props;
  const dispatch = useDispatch<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [userInfo, setUserInfo] = useState<any>();

  const listBank = useSelector((state: any) => state.topUp.listBank) || [];

  const status = [
    {
      value: 1,
      label: "chưa thanh toán",
    },
    {
      value: 2,
      label: "Đã thanh toán, chưa cộng credit",
    },
    {
      value: 3,
      label: "Đã thanh toán, đã cộng credit",
    },
  ];

  const banks = listBank.map((bank: any) => ({
    label: bank?.name,
    value: bank?.name,
  }));

  const handleShow = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    // props.onSubmit();

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validateOnMount: false,
    initialValues: {
      userIdBuy: info?.userIdBuy?.userName,
      accountBank: info?.accountBank,
      paymentNeed: info?.paymentNeed,
      creditWanted: info?.creditWanted,
      paymentContent: info?.paymentContent,
      paymentDate:
        info?.paymentDate === null
          ? format(Date.now(), "yyyy-MM-dd hh:mm")
          : format(new Date(info?.paymentDate), "yyyy-MM-dd hh:mm"),
      bank: info?.bank,
      status: info?.status,
      userApprove: userInfo?.userName,
      _id: info?._id,
    },
    validationSchema: Yup.object({
      accountBank: Yup.string().required(" (Số tài khoản không được để trống)"),
      bank: Yup.string().required(" (Mời chọn ngân hàng)"),
      creditWanted: Yup.number().required("(Mời nhập Creadit muốn mua)"),
    }),
    onSubmit: async (values: any) => {
      setIsLoading(true);
      try {
        values.userApprove = userInfo?._id;
        const result = dispatch(paymentManagerAction.updateOneInfo(values));
        result.then((data: any) => {
          setIsLoading(false);
          if (data.error) {
            toast.error(data?.error?.message || "Thêm thông tin thất bại");
            return;
          }
          if (info?.creditWanted !== values?.creditWanted)
            sendLogs(TypeGroup[6], TypeAction[5], {
              ...info,
              beforeCreadit: info?.creditWanted,
              afterCredit: values?.creditWanted,
            });
          else sendLogs(TypeGroup[6], TypeAction[2], info);

          toast.success(data.message || "Thêm thông tin thành công");
          setIsModalOpen(false);
          handleSearch();

          // props.onSubmit();
        });
      } catch (error) {
        toast.error("Thêm thông tin thất bại");
        setIsLoading(false);
      }
    },
  });

  // useEffect(() => {
  //    const result = LOCAL_STORAGE.getCurrentUser();
  //    setUserInfo(result);
  // }, []);

  return (
    <>
      <Popover content="Thêm nội dung thanh toán">
        <LuEdit2
          size={24}
          className="mr-2 cursor-pointer text-sky-500"
          onClick={handleShow}
        />
      </Popover>

      <Modal
        title={<h5>Thêm thông tin thanh toán</h5>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        footer={null}
        className="relative"
      >
        <FullPageSpiner isLoading={isLoading} />

        <form
          onSubmit={formik.handleSubmit}
          className="pt-6"
        >
          <div className="mb-4">
            <label
              htmlFor="input-user"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Người nạp
            </label>
            <input
              className="w-full rounded-md border border-gray-300 p-2.5"
              name="userIdBuy"
              id="input-user"
              value={formik.values.userIdBuy}
              disabled
            ></input>
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="input-account-number"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Số tài khoản <span className="text-red-500">*</span>
              {formik.errors.accountBank && formik.touched.accountBank && (
                <i className="text-sm font-normal text-red-500">
                  {formik.errors.accountBank.toString()}
                </i>
              )}
            </label>

            <input
              id="input-account-number"
              type="text"
              name="accountBank"
              value={formik.values.accountBank}
              onChange={formik.handleChange}
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
            ></input>
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="select-bank"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Chọn ngân hàng <span className="text-red-500">*</span>
              {formik.errors.bank && formik.touched.bank && (
                <i className="text-sm font-normal text-red-500">
                  {formik.errors.bank.toString()}
                </i>
              )}
            </label>
            <Select
              id="select-bank"
              value={formik.values.bank}
              className="w-full [&>div]:!h-10"
              options={banks || []}
              onChange={(value) => {
                formik.setFieldValue("bank", value);
              }}
            />
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="input-account-balance"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Số tiền thanh toán{" "}
              {formik.errors.paymentNeed && formik.touched.paymentNeed && (
                <i className="text-sm font-normal text-red-500">
                  {formik.errors.paymentNeed.toString()}
                </i>
              )}
            </label>

            <div className="relative w-full">
              <InputNumber
                className="w-full !py-1.5"
                name="paymentNeed"
                value={new Intl.NumberFormat("en").format(
                  formik.values.paymentNeed || 0,
                )}
                disabled
              />
              <div className="absolute right-1.5 top-1.5 bg-white p-1">
                <p className="text-gray-400">VND</p>
              </div>
            </div>
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="input-account-balance"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Credit muốn mua{" "}
              {formik.errors.creditWanted && formik.touched.creditWanted && (
                <i className="text-sm font-normal text-red-500">
                  {formik.errors.creditWanted.toString()}
                </i>
              )}
            </label>

            <div className="relative w-full">
              <InputNumber
                className="w-full !py-1.5"
                name="creditWanted"
                value={formik.values.creditWanted || 0}
                onChange={(value) => {
                  formik.setFieldValue("creditWanted", value);
                }}
              />
            </div>
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="select-status"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Trạng thái{" "}
            </label>
            <Select
              id="select-status"
              value={formik.values.status}
              className="w-full [&>div]:!h-10"
              options={
                info?.status === 2 || info?.status === 1
                  ? status.slice(0, 2)
                  : status || []
              }
              onChange={(value) => {
                formik.setFieldValue("status", value);
              }}
              disabled={info?.status === 3 ? true : false}
            />
          </div>

          <div className="mb-4 w-full">
            <label
              htmlFor="input-body-transfer"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Nội dung chuyển khoản
            </label>

            <input
              id="input-body-transfer"
              type="text"
              name="paymentContent"
              value={formik.values.paymentContent}
              onChange={formik.handleChange}
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
            ></input>
          </div>

          <div className="mb-8 w-full">
            <label
              htmlFor="input-paymentDate"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Ngày thanh toán
            </label>

            <input
              id="input-paymentDate"
              type="datetime-local"
              name="paymentDate"
              value={formik.values.paymentDate}
              onChange={formik.handleChange}
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
            ></input>
          </div>

          <div className="mb-4">
            <label
              htmlFor="input-admin"
              className="mb-1 line-clamp-1 text-base font-semibold"
            >
              Người xác nhận
            </label>
            <input
              className="w-full rounded-md border border-gray-300 p-2.5"
              name="userApprove"
              id="input-admin"
              value={formik.values.userApprove}
              disabled
            ></input>
          </div>

          {/* <div className="my-4">
                  <label htmlFor="input-admin" className="line-clamp-1 text-base font-semibold mb-1">
                     Ảnh hóa đơn
                  </label>
                  <img src={info?.imageInvoice} alt="imageInvoice" className="rounded-lg w-[450px] block mx-auto"/>
               </div>       */}

          <div className="flex justify-end gap-3">
            <PrimaryButton
              className="px-6 py-2"
              type="submit"
              content="Thay đổi"
            ></PrimaryButton>
            <SecondaryButton
              onClick={handleCancel}
              type="button"
              className="rounded-md bg-white px-3 py-2"
              content="Hủy"
            ></SecondaryButton>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PaymentEditModal;
