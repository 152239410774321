import { ApiInstance } from "helper/api.helper";

const getPagingPost = async (pageSize = 10, pageIndex = 1) => {
  return ApiInstance().get(`/post?pageSize=${pageSize}&pageIndex=${pageIndex}`);
};

const createNewPost = async (data: any) => {
  return ApiInstance().post(`/post`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const createComment = async ({ content, postId }: any) => {
  return ApiInstance().post(`/comment/${postId}`, { content });
};

const likePost = async (postId: any) => {
  return ApiInstance().put(`/post/${postId}/like`);
};

const unlikePost = async (postId: any) => {
  return ApiInstance().put(`/post/${postId}/un-like`);
};

const deletePost = async (postId: any) => {
  return ApiInstance().delete(`/post/${postId}`);
}

const getCommentByPost = async (postId: string) => {
  return ApiInstance().get(`/comment/${postId}?pageSize=1000&pageIndex=1`);
};

const deleteComment = async (cmtId: string) => {
  return ApiInstance().delete(`/comment/${cmtId}`);
};

const updateComment = async (cmtId: string, content: string) => {
  return ApiInstance().put(`/comment/${cmtId}`, { content });
}

export const postCommunityAPI = {
  getPagingPost,
  createNewPost,
  getCommentByPost,
  createComment,
  likePost,
  unlikePost,
  deleteComment,
  updateComment,
  deletePost,
};
