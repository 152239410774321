import { DatePicker, Input, Select, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { RangePickerProps } from "antd/es/date-picker";
import { autoTrafficAPI } from "api/auto-trafic.api";
import { OptionsSelect, htmfContentFake, htmlContent } from "./dataFake";
import moment from "moment";
import dayjs from "dayjs";
import { userAction } from "redux/user/user.slice";

interface IForm {
  name: string;
  domain: string;
  keyword: string;
  isFirstPage?: boolean;
  isClickLink?: boolean;
  isCopyText?: boolean;
  isClickButton?: boolean;
  isScroll?: boolean;
  status: number;
  package: string;
  totalAmount: number;
  quantity: number;
  completeTime: Array<Date>;
  price: number;
  script: string;
  totalTraffic: number;
}
interface IError {
  name?: any;
  url?: any;
  keyword?: any;
  completeTime?: any;
  quantity?: any;
}
const MakeProgressAutoTraffic = (props:any) => {
  const {setActive} = props;
  const [api, contextHolder] = notification.useNotification();
  const [error, setError] = useState<IError>({
    name: "",
    url: "",
    keyword: "",
    completeTime: "",
    quantity: 0,
  });
  const initForm: IForm = {
    name: "",
    domain: "",
    keyword: "",
    status: 0,
    package: "50s",
    totalAmount: 0,
    quantity: 0,
    completeTime: [],
    price: 1,
    script: htmlContent(50),
    totalTraffic: 0,
  };
  const [form, setForm] = useState<IForm>(initForm);
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch<any>();
  const handleOptionsSelect = (e: any) => {
    setForm({
      ...form,
      package: `${e}s`,
    });
  };

  useEffect(() => {
    let updatedForm = { ...form };
    if (form.package === "50s") {
      updatedForm = { ...updatedForm, price: 1 };
    }
    if (form.package === "60s") {
      updatedForm = { ...updatedForm, price: 1 };
    }
    if (form.package === "70s") {
      updatedForm = { ...updatedForm, price: 1 };
    }
    if (form.package === "90s") {
      updatedForm = { ...updatedForm, price: 1.2 };
    }
    if (form.package === "120s") {
      updatedForm = { ...updatedForm, price: 1.2 };
    }
    if (form.package === "150s") {
      updatedForm = { ...updatedForm, price: 1.5 };
    }
    if (form.package === "180s") {
      updatedForm = { ...updatedForm, price: 1.8 };
    }
    setForm(updatedForm);
    // Update script
    const index = form.package.indexOf("s");
    const numberOfPackage = form.package.slice(0, index);
    const script = htmlContent(Number(numberOfPackage));
    setForm((prevForm) => ({ ...prevForm, script })); // Sử dụng functional update để đảm bảo không ghi đè trực tiếp
  }, [form.package]);

  useEffect(() => {
    const newTotalMoney =
      form && form.price && form.quantity ? Math.round(form.price * form.totalTraffic) : 0;
    setForm((prevForm) => ({
      ...prevForm,
      totalAmount: newTotalMoney,
    }));
  }, [form.price, form.totalTraffic]);

  const onclickMakeProgress = async () => {
    let hasError = false; // Biến để theo dõi xem có lỗi hay không

    if (form.name === "") {
      setError({ ...error, name: "error" });
      api.error({
        message: "Tên nhiệm vụ không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.domain === "") {
      setError({ ...error, url: "error" });
      api.error({
        message: "Tên miền không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.keyword === "") {
      setError({ ...error, keyword: "error" });
      api.error({
        message: "Từ khóa không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.quantity === 0) {
      setError({ ...error, quantity: "error" });
      api.error({
        message: "Số traffic cho nhiệm vụ này phải lớn hơn 0!",
      });
      hasError = true;
      return;
    } else if (form.completeTime.length === 0 || form.completeTime === null) {
      setError({ ...error, completeTime: "error" });
      api.error({
        message: "Thời hạn hoàn thành không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (!hasError) {
      const dataSend = {
        completeTime: form?.completeTime,
        name: form?.name?.trim(),
        domain: form?.domain?.trim(),
        keyword: form?.keyword?.trim(),
        package: form?.package,
        quantity: form?.quantity,
        script: form?.script,
        totalTraffic: form?.totalTraffic,
      };
      const result: any = await autoTrafficAPI.missionTraffic(dataSend);
      if (result.status === 200) {
        api.success({
          message: "Tạo nhiệm vụ thành công!",
          icon: <BsCheckCircleFill size={30} className="text-lime-600" />,
        });
        setForm({
          ...initForm,
          completeTime: form.completeTime,
          script: form?.script,
          package: form?.package,
        });
        setTimeout(() => {
          setActive(1)
        }, 500);
        dispatch(
          userAction.getProfile()
        )
      }
      if (result.status === 400) {
        api.warning({
          message: `${result.message}`,
          icon: <FaMoneyBillWave size={30} className="text-lime-600" />,
        });
      }
      if (result.status === 500) {
        api.warning({
          message: `${result.message}`,
          icon: <FaMoneyBillWave size={30} className="text-lime-600" />,
        });
      }
    }
  };

  const handleDate = (
    value: any | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setForm({
        ...form,
        completeTime: [],
      });
    } else {
      const arrDate = [value[0].toDate(), value[1].toDate()];
      const diff = value[1].diff(value[0], "day", true);
      const totalTraffic = form.quantity * diff;

      setError({ ...error, completeTime: "" });
      setForm({
        ...form,
        completeTime: arrDate,
        totalTraffic,
      });
    }
  };

  // const handleCheckBox = (e: any, id: number) => {
  //   switch (id) {
  //     case 0:
  //       return setForm({
  //         ...form,
  //         isFirstPage: e.target.checked,
  //       });

  //     case 1:
  //       return setForm({
  //         ...form,
  //         isClickLink: e.target.checked,
  //       });

  //     case 2:
  //       return setForm({
  //         ...form,
  //         isCopyText: e.target.checked,
  //       });

  //     case 3:
  //       return setForm({
  //         ...form,
  //         isClickButton: e.target.checked,
  //       });

  //     case 4:
  //       return setForm({
  //         ...form,
  //         isScroll: e.target.checked,
  //       });

  //     default:
  //       return;
  //   }
  // };

  return (
    <div className="mt-2">
      {contextHolder}

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">
          Nhập tên nhiệm vụ
        </div>
        <Input
          className="h-[40px] mt-1"
          placeholder="Nhập tên nhiệm vụ"
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
            setError({ ...error, name: "" });
          }}
          value={form.name}
          status={error.name}
        />
      </div>

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">Nhập tên miền</div>
        <Input
          className="h-[40px] mt-1"
          placeholder="ví dụ: https://congdongseo.com"
          onChange={(e) => {
            setForm({ ...form, domain: e.target.value });
            setError({ ...error, url: "" });
          }}
          value={form.domain}
          status={error.url}
        />
      </div>

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">Nhập từ khóa</div>
        <Input
          className="h-[40px] mt-1"
          placeholder="Nhập từ khóa"
          onChange={(e) => {
            setForm({ ...form, keyword: e.target.value });
            setError({ ...error, keyword: "" });
          }}
          value={form.keyword}
          status={error.keyword}
        />
      </div>

      <div className="sm:block md:flex">
        <div className="mt-2 w-full py-1 pr-1">
          <div className="text-lg text-green-500 font-bold">
            Số traffic theo ngày
          </div>
          <Input
            className="h-[40px] mt-1"
            placeholder=""
            type="number"
            value={form.quantity}
            onChange={(e) => {
              const diff =
                dayjs(form.completeTime[1]).diff(
                  dayjs(form.completeTime[0]),
                  "day",
                  true
                ) || 0;
              const totalTraffic = Number(e.target.value) * diff;
              setForm({
                ...form,
                quantity: Number(e.target.value),
                totalTraffic,
              });

              setError({ ...error, quantity: 0 });
            }}
            status={error.quantity}
          />
        </div>
      </div>

      <div className="sm:block md:flex">
        <div id="google-search" className="mt-2 w-full sm:w-full md:w-1/2 py-1 pr-1">
          <div className="text-lg text-green-500 font-bold">Gói onsite:</div>
          <Select
            className="mt-1"
            style={{ width: "100%" }}
            onChange={handleOptionsSelect}
            options={OptionsSelect}
            placeholder={OptionsSelect[0].label}
          />
        </div>
        <div className="mt-2 w-full sm:w-full md:w-1/2 p-1">
          <div className="text-lg text-green-500 font-bold">Giá 1 traffic</div>
          <div className="relative">
            <Input
              className="h-[40px] mt-1"
              placeholder="Nhập url đích muốn chạy traffic"
              value={form.price}
            />
            <div className="absolute top-3.5 right-3 text-gray-500">
              credit(s)
            </div>
          </div>
        </div>
      </div>

      <div className="block md:flex lg:flex">
        <div className="mt-2 lg:w-1/2 md:w-1/2 w-full py-1 pr-1">
          <div className="text-lg text-green-500 font-bold">
            Thời hạn hoàn thành
          </div>
          <div className="w-full date-width">
            <Space direction="vertical" size={12} className="w-full">
              <RangePicker
                format={"DD-MM-YYYY"}
                disabledDate={(current) => {
                  return current && current < moment().subtract(1, "days");
                }}
                onChange={handleDate}
                status={error.completeTime}
                placeholder={["Từ ngày", "Đến ngày"]}
                placement="bottomRight"
              />
            </Space>
          </div>
        </div>
        <div className="mt-2 w-full sm:w-full md:w-1/2 p-1">
          <div className="text-lg text-green-500 font-bold">
            Tổng số traffic
          </div>
          <Input className="h-[40px] mt-1" value={form.totalTraffic} />
        </div>
        {/* <div className="w-1/2 my-auto p-1">
          <div className="text-lg text-green-500 font-bold">
            Trạng thái hoạt động
          </div>
          <div>
            <Tooltip title='hoạt động'>
              <Switch defaultChecked/>
            </Tooltip>
          </div>
        </div> */}
      </div>

      {/* <div className="p-1">
        <div className="text-lg text-green-500 font-bold">Cài đặt</div>
        <div className="flex flex-wrap gap-10">
          {TooltipSetup.map((item: any, index: number) => (
            <div className="flex" key={index}>
              <Checkbox
                onChange={(e) => handleCheckBox(e, item.id)}
                checked={
                  item.id === 0
                    ? form.isFirstPage
                    : item.id === 1
                    ? form.isClickLink
                    : item.id === 2
                    ? form.isCopyText
                    : item.id === 3
                    ? form.isClickButton
                    : form.isScroll
                }
              >
                {item.title}
              </Checkbox>
              <Tooltip title={item.content}>
                <BsFillPatchQuestionFill className="my-auto text-[#00a307] cursor-pointer" />
              </Tooltip>
            </div>
          ))}
        </div>
      </div> */}

      <div className="p-3 rounded-md bg-red-100 mt-2">
        <div className="text-red-400 text-lg">
          Đoạn code mẫu gắn vào Footer web đích
        </div>
        <div className="mt-2 p-2 bg-black-80 text-white">
          {/* <pre>{htmlContent(Number(form.TimeOnSite))}</pre> */}
          <pre className="break-words whitespace-pre-wrap">{htmfContentFake()}</pre>
        </div>
        <div className="text-lg text-cyan-700 mt-1">
          + Tiến hành copy đoạn code ở mục hành động trong nhật kí order và gắn
          vào Footer của website.
        </div>
        <div className="text-lg text-cyan-700 mt-1">
          + Cần tắt các plugin Wprocket (nếu có) để tối ưu với thời gian thực
        </div>
        <div className="text-lg text-cyan-700 mt-1 lg:flex md:block block">
          <div className="my-auto">
            + Sau khi cài đặt thành công web sẽ hiện lên ô nhận mã màu xanh lá
            như sau:
          </div>
          <div className="my-auto ml-2">
            <img
              src={"/imgs/buttonCode.png"}
              alt="image-button"
              width={150}
              height={150}
            />
          </div>
        </div>
        <div className="text-lg text-cyan-700 mt-1">
          + Sau khi vào Google.com tìm từ khóa rồi click vào web thấy xuất hiện
          nút "Mời bạn lấy mã" màu xanh lá như hình phía trên nghĩa là bạn đã
          cài đặt thành công.
        </div>
        <div className="text-lg text-cyan-700 mt-1">
          + Sau khi cài đặt xong đoạn script, bạn cần vào nhật kí order để tiến
          hành cho chạy nhiệm vụ.
        </div>
      </div>

      {/* <div className="sm:block md:flex">
        <div className="mt-2 w-full p-1">
          <div className="text-lg text-green-500 font-bold">
            Nhận số điện thoại (Không bắt buộc - Dùng để chúng tôi liên lạc khi
            đặt sai mã)
          </div>
          <Input
            className="h-[40px] mt-1"
            placeholder="Nhận số điện thoại để nhận hỗ trợ!"
            value={form.phoneNumber}
            onChange={(e) => {
              setForm({ ...form, phoneNumber: e.target.value });
            }}
          />
        </div>
      </div> */}

      <div className="mt-5 bg-yellow-500 rounded-lg p-4 text-center">
        <div className="text-base font-bold text-white">
          Tổng: {form?.totalAmount || 0} credits
        </div>
        <div className="text-base font-normal text-white">
          Bạn sẽ mua{" "}
          <span className="font-bold">{form.totalTraffic} lượt traffic</span>{" "}
          với giá
          <span className="font-bold"> {form.price} credits</span> / traffic
        </div>
      </div>
      <button
        onClick={() => onclickMakeProgress()}
        className=" mt-5 w-full text-center bg-sky-800 hover:bg-sky-700 font-bold text-base text-white p-4 rounded-lg"
      >
        Tạo nhiệm vụ
      </button>
    </div>
  );
};

export default MakeProgressAutoTraffic;
