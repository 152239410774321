import { MdOutlineSort, MdMenu } from "react-icons/md";
import { Button } from "antd";
import AvatarGroup from "components/ui/avatar/AvatarGroup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "./Notification";

const Header = ({ collapsed, setCollapsed }: any) => {

    const userInfo = useSelector((state:any) => state.user.currentUser);
    const navigate = useNavigate();
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    if (screenSize.width < 1000 && !collapsed) setCollapsed(true);

    useEffect(() => {
        const updateDimension = () => {
            const screenSize = getCurrentDimension();
            if (screenSize.width < 1000 && !collapsed) setCollapsed(true);

            setScreenSize(screenSize);
        };
        window.addEventListener("resize", updateDimension);

        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenSize]);

    return (
        <div className="flex border-b border-gray-300 bg-white py-6 px-4">
            <Button
                type="text"
                icon={
                    collapsed ? (
                        <MdMenu size={25} />
                    ) : (
                        <MdOutlineSort size={25} />
                    )
                }
                onClick={() => setCollapsed(!collapsed)}
                className="lg:block md:block hidden"
            />
            <div className="flex ml-auto">
                <div className="ml-auto mr-5 my-auto lg:text-base md:text-base text-sm lg:flex md:flex block">
                    <div 
                        className="ml-auto lg:mr-5 md:mr-5 mr-0 border px-2 py-2 rounded-lg 
                        bg-[#0ea5e9] text-white hover:opacity-50 cursor-pointer"
                        onClick={() => navigate("/nap-tien")}
                    >
                        <span className="whitespace-nowrap">Mua credit</span>
                    </div>
                    <div className="my-auto w-full">
                        Số dư: <span className="text-[#9ea8fa] font-bold break-words max-w-full">{new Intl.NumberFormat("en").format(userInfo?.credit || 0)}</span>
                    </div>
                </div>
                <Notification/>
            </div>
            <AvatarGroup></AvatarGroup>
        </div>
    );
};
export default Header;
