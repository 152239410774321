import { useFormik } from "formik";
import { useState } from "react";
import { AiOutlineEdit, AiOutlineKey } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import { BsUpload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Button, Input, Space, message } from "antd";
import defaultAvatar from "assets/images/avatar/default.jpg";
import { GenderConst } from "common/constant";
import PrimaryButton from "components/ui/button/PrimaryButtton";
import SecondaryButton from "components/ui/button/SecondaryButton";
import FullPageSpiner from "components/ui/spiner/FullPageSpiner";
import { format } from "date-fns";
import { FaCheck, FaRegCopy } from "react-icons/fa";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { appAction } from "redux/app/app.slice";
import { userAction } from "redux/user/user.slice";
import ChangePassword from "./ChangePassword";

function Profile() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const userInfo = useSelector((state: any) => state.user.currentUser);

  const isLoading: boolean =
    useSelector((state: any) => state.user.isLoading) || false;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarUrl, seAvatarUrl] = useState<any>("");
  const [isCopy, setIsCopy] = useState(false);

  const handleCopy = async (text: string) => {
    try {
      setIsCopy(true);
      await navigator.clipboard.writeText(text);
      message.success("Copy link thành công");
    } catch (error) {
      message.error("Copy link thất bại");
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSelected = e.currentTarget.files?.[0];
    if (typeof fileSelected === "undefined") return;

    const reader = new FileReader();
    reader.onloadend = () => {
      seAvatarUrl(reader.result);
    };
    if (fileSelected.type.match(/image.*/)) {
      setAvatarFile(fileSelected);
      reader.readAsDataURL(fileSelected);
    }
  };

  const handleUploadFile = async (file: File | null) => {
    try {
      const formData = new FormData();
      if (file) formData.append("file", file);

      // call api upload
      const uploadResult = await dispatch(appAction.uploadFile(formData));
      return uploadResult.payload.data.url;
    } catch (err) {
      throw new Error("Upload fail");
    }
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    validateOnMount: false,
    initialValues: {
      userName: userInfo?.userName || "",
      dateOfBirth: userInfo?.dateOfBirth || null,
      fullName: userInfo?.fullName || "",
      facebook: userInfo?.facebook || "",
      email: userInfo?.email || "",
      phoneNumber: userInfo?.phoneNumber || "",
      address: userInfo?.address || "",
      gender:
        !userInfo?.gender && userInfo?.gender !== 0
          ? GenderConst.orther
          : userInfo?.gender,
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("* Required"),
      fullName: Yup.string().required("* Required"),
      email: Yup.string().required("* Required").email("Invalid email"),
      phoneNumber: Yup.string().matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Invalid phone number",
      ),
    }),
    onSubmit: async (values: any) => {
      try {
        let avatarUpload = userInfo?.avatar;
        if (avatarUrl && avatarUrl !== userInfo?.avatar) {
          avatarUpload = await handleUploadFile(avatarFile);
        }
        const result = dispatch(
          userAction.updateProfile({
            ...values,
            avatar: avatarUpload,
          }),
        );
        result.then((data: any) => {
          if (data.error) {
            toast.error(data?.error?.message || "Update Fail");
            return;
          }
          //
          toast.success(data.message || "Update Successfully");
          dispatch(userAction.getProfile());
        });
      } catch (error) {
        toast.error("Update Fail");
      }
    },
  });

  return (
    <div className="relative h-full p-4">
      <FullPageSpiner isLoading={isLoading} />

      <div className="my-4 flex justify-between">
        <h3 className="font-semibold">Thông tin Tài khoản</h3>

        <SecondaryButton
          className="hover:border-yellow-500"
          background="bg-white hover:bg-yellow-400"
          content="Mua credit"
          onClick={() => navigate("/nap-tien")}
          icon={
            <RiMoneyEuroCircleLine
              className="me-2"
              size={20}
            />
          }
        />
      </div>

      <div className="grid grid-cols-4 rounded-xl border border-gray-300 bg-white p-6 sm:p-12">
        <div className="col-span-4 mb-8 md:col-span-1">
          <div className="relative mx-auto mb-5 aspect-square max-h-40">
            <img
              src={avatarUrl || userInfo?.avatar || defaultAvatar}
              alt="user-avatar"
              className="absolute h-full w-full rounded-full object-cover"
            ></img>

            <div className="absolute flex h-full w-full items-center justify-center">
              <input
                id="input-avatar"
                type="file"
                className="hidden"
                name="avatar"
                accept="image/*"
                onChange={handleSelectFile}
              ></input>
              <label
                htmlFor="input-avatar"
                className="flex h-full w-full items-center justify-center rounded-full bg-gray-300 opacity-0 hover:opacity-50"
              >
                <BiImageAdd
                  size={50}
                  className="text-gray-400"
                />
              </label>

              <label
                htmlFor="input-avatar"
                className="absolute bottom-1 right-1"
              >
                <AiOutlineEdit size={20} />
              </label>
            </div>
          </div>

          <div className="mb-5 flex justify-center">
            <div className="me-5">
              <input
                id="male-check-box"
                type="radio"
                name="gender"
                className="me-2 accent-primary-300"
                checked={GenderConst.male === formik.values.gender}
                onChange={() => {
                  formik.setFieldValue("gender", GenderConst.male);
                }}
              ></input>
              <label htmlFor="male-check-box">Nam</label>
            </div>
            <div className="me-5">
              <input
                id="fermale-check-box"
                type="radio"
                name="gender"
                checked={GenderConst.fermale === formik.values.gender}
                onChange={() => {
                  formik.setFieldValue("gender", GenderConst.fermale);
                }}
                className="me-2 accent-primary-300"
              ></input>
              <label htmlFor="fermale-check-box">Nữ</label>
            </div>
            <div>
              <input
                id="orther-check-box"
                type="radio"
                name="gender"
                checked={GenderConst.orther === formik.values.gender}
                onChange={() => {
                  formik.setFieldValue("gender", GenderConst.orther);
                }}
                className="me-2 accent-primary-300"
              ></input>
              <label htmlFor="orther-check-box">Khác</label>
            </div>
          </div>

          <div>
            <h5 className="text-center">
              Số credit:{" "}
              <b>{new Intl.NumberFormat("vi").format(userInfo?.credit || 0)}</b>
            </h5>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="col-span-4 flex justify-center sm:px-6 md:col-span-3"
        >
          <div className="flex w-full max-w-4xl flex-col gap-y-6">
            <div className="flex w-full flex-wrap gap-x-8 gap-y-6 sm:flex-nowrap">
              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-username"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Tên đăng nhập{" "}
                  {formik.errors.userName && formik.touched.userName && (
                    <i className="text-sm text-red-500">
                      {formik.errors.userName.toString()}
                    </i>
                  )}
                </label>

                <input
                  id="input-username"
                  name="userName"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  value={formik.values.userName}
                  //onChange={formik.handleChange}
                  disabled
                ></input>
              </div>

              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-dateOfBirth"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Ngày sinh
                </label>
                <input
                  id="input-dateOfBirth"
                  type="date"
                  name="dateOfBirth"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  value={
                    formik.values.dateOfBirth
                      ? format(
                          new Date(formik.values.dateOfBirth),
                          "yyyy-MM-dd",
                        )
                      : ""
                  }
                  onChange={formik.handleChange}
                ></input>
              </div>
            </div>

            <div className="flex w-full flex-wrap gap-x-8 gap-y-6 sm:flex-nowrap">
              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-fullname"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Họ tên{" "}
                  {formik.errors.fullName && formik.touched.fullName && (
                    <i className="text-sm text-red-500">
                      {formik.errors.fullName.toString()}
                    </i>
                  )}
                </label>
                <input
                  name="fullName"
                  id="input-fullname"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                ></input>
              </div>

              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-facebook"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Facebook
                </label>
                <input
                  name="facebook"
                  id="input-facebook"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                ></input>
              </div>
            </div>

            <div className="flex w-full flex-wrap gap-x-8 gap-y-6 sm:flex-nowrap">
              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-email"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Email{" "}
                  {formik.errors.email && formik.touched.email && (
                    <i className="text-sm text-red-500">
                      {formik.errors.email.toString()}
                    </i>
                  )}
                </label>
                <input
                  name="email"
                  id="input-email"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  //onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled
                ></input>
              </div>

              <div className="w-full sm:w-1/2">
                <label
                  htmlFor="input-phone"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Số điện thoại{" "}
                  {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <i className="text-sm text-red-500">
                      {formik.errors.phoneNumber.toString()}
                    </i>
                  )}
                </label>
                <input
                  id="input-phone"
                  name="phoneNumber"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                ></input>
              </div>
            </div>

            <div className="mb-1 flex w-full flex-wrap sm:flex-nowrap">
              <div className="w-full">
                <label
                  htmlFor="input-address"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Địa chỉ
                </label>
                <input
                  name="address"
                  id="input-address"
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                ></input>
              </div>
            </div>
            <div className="mb-8 flex w-full flex-wrap sm:flex-nowrap">
              <div className="w-full">
                <label
                  htmlFor="input-address"
                  className="mb-1 line-clamp-1 text-base font-semibold"
                >
                  Link giới thiệu
                </label>

                <Space.Compact className="w-full">
                  <Input
                    defaultValue={`${process.env.REACT_APP_CONG_DONG_SEO_URL}/dang-ki?referralCode=${userInfo?.referralCode}`}
                    disabled
                  />
                  <Button
                    className="rounded-lg bg-gradient-to-r from-primary-500 to-primary-800 leading-6 text-white hover:opacity-70"
                    onClick={() =>
                      handleCopy(
                        `${process.env.REACT_APP_CONG_DONG_SEO_URL}/dang-ki?referralCode=${userInfo?.referralCode}`,
                      )
                    }
                  >
                    {isCopy ? <FaCheck /> : <FaRegCopy />}
                  </Button>
                </Space.Compact>
              </div>
            </div>

            <div className="flex w-full flex-wrap gap-4">
              <PrimaryButton
                content="Cập nhật"
                type="submit"
                icon={<BsUpload className="me-2 text-xl" />}
              />

              <ChangePassword
                showButton={
                  <SecondaryButton
                    content="Đổi mật khẩu"
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    icon={<AiOutlineKey className="me-2 text-xl" />}
                  />
                }
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Profile;
