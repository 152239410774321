import { DatePicker, Space } from "antd";
import NewPost from "components/pages/Home/NewPost";
import Notification from "components/pages/Home/Notification";
import Post from "components/pages/Home/Post";
import moment from "moment";
import "moment/locale/vi";
import React, { useEffect, useState } from "react";
import { BiWallet } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getPagingPost,
  loadMore,
} from "redux/postCommunity/postCommunity.slice";
import { userAction } from "redux/user/user.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "components/ui/spiner/Loader";

function Home() {
  moment.locale("vi");
  const dispatch = useDispatch<any>();
  const { posts, totalDoc } = useSelector((state: any) => state.postsComunity);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const { totalCreditWanted, totalCreditWantedFollowMonth } = useSelector(
    (state: any) => state.user,
  );

  useEffect(() => {
    dispatch(getPagingPost({ pageSize: 10, pageIndex: 1 }));
    dispatch(userAction.getSumCreditWanted());
  }, []);
  useEffect(() => {
    if (pageIndex > 1) {
      dispatch(loadMore({ pageSize: 10, pageIndex: pageIndex }));
    }
  }, [pageIndex]);

  return (
    <div className="mx-auto grid max-w-6xl grid-cols-9 gap-x-8 gap-y-4 p-4">
      <div className="report col-span-9 space-y-4 lg:order-2 lg:col-span-4">
        <Notification />
        <Report
          totalCreditWanted={totalCreditWanted}
          totalCreditWantedFollowMonth={totalCreditWantedFollowMonth}
        />
        <Statistic />
      </div>
      <div className="posts col-span-9 space-y-4 lg:order-1 lg:col-span-5">
        <NewPost />
        {/* <div className={`posts space-y-5`}>
          {posts.map((post: any, index: number) => (
            <React.Fragment key={index}>
              <Post post={post} />
            </React.Fragment>
          ))}
        </div> */}

        <InfiniteScroll
          dataLength={posts.length}
          next={() => setPageIndex((prev) => prev + 1)}
          hasMore={posts.length < totalDoc}
          loader={<Loader />}
          className="hide-scrollbar space-y-5"
        >
          {posts.map((post: any, index: number) => (
            <React.Fragment key={index}>
              <Post post={post} />
            </React.Fragment>
          ))}
        </InfiniteScroll>

        {/* {totalDoc > 10 && totalDoc > posts.length && (
          <p onClick={() => setPageIndex((prev) => prev + 1)}>See more</p>
        )} */}
      </div>
    </div>
  );
}
export default Home;

export const Statistic = (data: any) => {
  const { RangePicker } = DatePicker;
  const chartData = {
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
    ],
  };
  console.log("home render");

  return (
    <div className={`myHomeContainer space-y-4`}>
      <p className="font-bold">Thống kê</p>
      <Space
        direction="vertical"
        size={12}
      >
        <RangePicker showTime />
      </Space>
      {/* <Chart options={chartData.options} series={chartData.series} type="line" width={500} height={500}/> */}
    </div>
  );
};

const Report = ({
  totalCreditWanted,
  totalCreditWantedFollowMonth,
}: {
  totalCreditWanted: any;
  totalCreditWantedFollowMonth: any;
}) => {
  const Property = ({
    value,
    description,
  }: {
    value: any;
    description: string;
  }) => {
    return (
      <div className="col-span-2 flex items-center gap-x-4 md:col-span-1">
        <div className="flex aspect-square h-full items-center justify-center rounded-md bg-green-200">
          <BiWallet size={24} />
        </div>
        <div>
          <p className="text-lg font-bold">{value} credits</p>
          <p className="text-light text-sm text-gray-600">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={`myHomeContainer space-y-4`}>
      <p className="font-bold">Báo cáo</p>
      <div className="grid grid-cols-2 gap-4">
        <Property
          value={totalCreditWanted}
          description="tổng nạp theo tháng:"
        />
        <Property
          value={totalCreditWantedFollowMonth}
          description="tổng nạp:"
        />
      </div>
    </div>
  );
};
