import { ApiInstanceTraffic } from "helper/api.helper";

export const autoClickSpamAPI = {
  createAutoSpam,
  getPaggingAutoSpamByUser,
  getInfoTagsList,
  runMissionAutoSpam,
  pauseMissionAutoSpam,
  deleteOneMissionAutoSpam,
  deleteManyMissionAutoSpam,
  handleAcceptedMissionAutoSpam,
  getPaggingMissionAutoSpam,
  deleteMutipleAutoSpam,
  handleRunMissionAutoSpam,
};

function createAutoSpam(body: any) {
  const url = `auto-spam`;
  return ApiInstanceTraffic().post(url, body);
}

function getPaggingAutoSpamByUser(query: {
  pageIndex: number;
  pageSize: number;
  search: string;
  statusFilter: number[];
}) {
  const url = `auto-spam?pageIndex=${query.pageIndex}&pageSize=${query.pageSize}&search=${query.search}&statusFilter=${JSON.stringify(query.statusFilter)}`;
  return ApiInstanceTraffic().get(url);
}

function getInfoTagsList() {
  const url = `auto-spam/count-tags`;
  return ApiInstanceTraffic().get(url);
}

function runMissionAutoSpam(id: any) {
  const url = `auto-spam/${id}/run`;
  return ApiInstanceTraffic().put(url);
}

function pauseMissionAutoSpam(id: any) {
  const url = `auto-spam/${id}/pause`;
  return ApiInstanceTraffic().put(url);
}

function deleteOneMissionAutoSpam(id: any) {
  const url = `auto-spam/${id}`;
  return ApiInstanceTraffic().delete(url);
}

function deleteManyMissionAutoSpam(query: any) {
  const url = `auto-spam/deleteMany`;
  return ApiInstanceTraffic().delete(url, { params: query });
}

function getPaggingMissionAutoSpam(query: any) {
    const url = `auto-spam/getPagging?pageIndex=${query.pageIndex}&pageSize=${query.pageSize}&search=${query.search}`;
    return ApiInstanceTraffic().get(url);
}

function handleAcceptedMissionAutoSpam(id: string, mission: string) {
    const url = `auto-spam/${id}/${mission}`;
    return ApiInstanceTraffic().put(url);
}

function handleRunMissionAutoSpam(id: string, data: any) {
    const url = `auto-spam/${id}/run`;
    return ApiInstanceTraffic().put(url, data);
}

function deleteMutipleAutoSpam(arrId: string[]) {
  const query: string = arrId.reduce((accumulator, currentValue) => {
    return accumulator + `selectedId=${currentValue}` + "&";
  }, "");
  const url = `auto-spam/deleteMany?${query.slice(0, -1)}`;
  return ApiInstanceTraffic().delete(url, { params: arrId });
}

