import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { actionsHistorymAPI } from "api/actions-history.api";
const getListLog = createAsyncThunk(
  "action-history/getPaggingActionHistory",
  (query: any = {}) => {
    return actionsHistorymAPI.getList(query);
  },
);
export const logAction = { getListLog };
export const logsSlice = createSlice({
  name: "logs",
  initialState: {
    listLogs: [],
    count: 0,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListLog.pending, (state, action: any) => {
      state.isLoading = true;
    });
    builder.addCase(getListLog.fulfilled, (state, action: any) => {
      state.listLogs = action.payload.data;
      state.count = action.payload.count;
      state.isLoading = false;
    });
    builder.addCase(getListLog.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message || "Get fail");
    });
  },
});
