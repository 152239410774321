const NewsStatus = [
  {
    value: 1,
    label: "Đã duyệt",
  },
  {
    value: 2,
    label: "Chờ xét duyệt",
  },
  {
    value: 3,
    label: "Nháp",
  },
];

const NotificationType = [
  {
    value: 1,
    label: "Gửi qua chuông thông báo",
  },
  {
    value: 2,
    label: "Gửi qua email",
  },
  {
    value: 3,
    label: "Gửi qua chuông thông báo và email",
  },
];

const NotificationStatusType = [
  {
    value: 1,
    label: "Lưu nháp",
  },
  {
    value: 2,
    label: "Tạo và gửi thông báo",
  },
];

const PostsStatus = [
  {
    value: 1,
    label: "Đã duyệt",
  },
  {
    value: 2,
    label: "Chờ xét duyệt",
  },
  {
    value: 3,
    label: "Bài sửa chờ duyệt",
  },
];

const ReportStatus = [
  {
    value: 1,
    label: "Đã xử lý",
  },
  {
    value: 2,
    label: "Chưa xử lý",
  },
  {
    value: 3,
    label: "Chờ xác thực",
  },
];

const GenderConst = {
  male: 0,
  fermale: 1,
  orther: 2,
};

const AccountStatus = [
  {
    value: 0,
    label: "Chưa kích hoạt",
  },
  {
    value: 1,
    label: "Đã kích hoạt",
  },
  {
    value: 2,
    label: "Đã xóa",
  },
];

const ROLES = {
  ADMIN: "admin",
  USER: "user",
  SALES: "sales",
};

const ACCOUNT_TYPES = [
  {
    value: 0,
    label: "Người dùng congdongseo",
  },
  {
    value: 1,
    label: "Người dùng admin.congdongseo",
  },
  {
    value: 2,
    label: "Quản trị viên",
  },
];

const CARD_TOP_UP_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  CANCELLED: "cancelled",
};

const WITHDRAW_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  REJECT: "reject",
};

// MANAGE_MEMBER = "Quản lý thành viên",
// MANAGE_SECTORS = "Quản lý chuyên mục",
// MANAGE_POSTS = "Quản lý bài viết",
// MANAGE_NOTIFICATION_CDS = "Quản lý thông báo",
// MANAGE_WITHDRAW = "Quản lý rút tiền admin",
// MANAGE_PAYMENT_CDS = "Quản lý thanh toán CDS",
// MANAGE_YOUTUBE_JOB = "Quản lý youtube",
// MANAGE_TWITTER_JOB = "Quản lý twitter",
// MANAGE_FACEBOOK_JOB = "Quản lý facebook",
// MANAGE_GOOGLE_JOB = "Quản lý google",
// MANAGE_GOOGLE_MAP_JOB = "Quản lý google map",
// MANAGE_INSTAGRAM_JOB = "Quản lý Instagram",
// MANAGE_PROXY = "Quản lý Proxy",
// MANAGE_TRAFFIC = "Quản lý traffic",
// MANAGE_AUTO_CLICK_SPAM = "Quản lý auto click spam",
// MANAGE_AUTO_CLICK_SPAM_ADS = "Quản lý auto click spam ADS",

enum TypeGroup {
  LOGIN, // 0
  MANAGE_MEMBER, // 1
  MANAGE_SECTORS, // 2
  MANAGE_POSTS, // 3
  MANAGE_NOTIFICATION_CDS, // 4
  MANAGE_WITHDRAW, // 5
  MANAGE_PAYMENT_CDS, // 6
  MANAGE_YOUTUBE_JOB, // 7
  MANAGE_TWITTER_JOB, // 8
  MANAGE_FACEBOOK_JOB, // 9
  MANAGE_GOOGLE_JOB, // 10
  MANAGE_GOOGLE_MAP_JOB, // 11
  MANAGE_INSTAGRAM_JOB, // 12
  MANAGE_PROXY, // 13
  MANAGE_TRAFFIC, // 14
  MANAGE_AUTO_CLICK_SPAM, // 15
  MANAGE_AUTO_CLICK_SPAM_ADS, // 16
}

enum TypeAction {
  LOGIN, // 0
  ADD, // 1
  UPDATE, // 2
  DELETE, // 3
  APPROVE, // 4
  UPDATE_CREDIT, //5
  REDO,//6
}
const Group = [
  "Đăng Nhập", // 0
  "Quản Lý Thành Viên", // 1
  "Quản Lý Sector", // 2
  "Quản Lý Bài Viết", // 3
  "Quản Lý Thông Báo CDS", // 4
  "Quản Lý WithDraw", // 5
  "Quản Lý Thanh Toán CDS", // 6
  "Quản Lý Youtube", // 7
  "Quản Lý Twitter", // 8
  "Quản Lý Facebook", // 9
  "Quản Lý Google", // 10
  "Quản Lý Google Map", // 11
  "Quản Lý Instagram", // 12
  "Quản Lý Proxy", // 13
  "Quản Lý Traffic", // 14
  "Quản Lý Auto Click Spam", // 15
  "Quản Lý Auto Click Spam ADS", // 16
];
const Action = [
  "Đăng Nhập", // 0
  "Thêm", // 1
  "Cập Nhật", // 2
  "Xóa", // 3
  "Chấp Nhận", // 4
  "Cập Nhật Credit", //5
];
export {
  NewsStatus,
  PostsStatus,
  ReportStatus,
  GenderConst,
  AccountStatus,
  ROLES,
  ACCOUNT_TYPES,
  CARD_TOP_UP_STATUS,
  WITHDRAW_STATUS,
  NotificationType,
  NotificationStatusType,
  TypeGroup,
  TypeAction,
  Group,
  Action,
};
