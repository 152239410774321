import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ProxyForm from "../proxy-form/ProxyForm";
import { useEffect } from "react";
import { proxyAction } from "redux/proxy/proxy.slice";

const proxyList = ["socks5", "tmproxy", "proxyno1", "tinsoftproxy"];
const ManageProxy = () => {
  const { isLoading, data } = useSelector((state: any) => state.proxy);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(proxyAction.getListProxy());
  }, [dispatch]);

  return (
    <div className="w-full p-2">
      <Spin spinning={isLoading} size="large">
        <Row gutter={[24, 24]}>
          {proxyList.map((item, index) => (
            <Col md={12} span={24} key={index}>
              <ProxyForm
                type={item}
                title={item}
                listProxy={
                  data.find((proxy: any) => proxy?.type === item)?.list
                }
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </div>
  );
};

export default ManageProxy;
