import moment from "moment";
import { memo, useState } from "react";
import { AiFillHeart, AiOutlineDelete } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deletePost as dispatchDeletePost,
  getCommentByPost,
  likePost,
  unlikePost,
} from "redux/postCommunity/postCommunity.slice";
import Comments from "./Comments";
import InputComment from "./InputComment";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { LuMessageCircle } from "react-icons/lu";
import { TbMessageCircle2Filled } from "react-icons/tb";
const Post = ({ post }: any) => {
  moment.locale("vi");
  const dispatch = useDispatch<any>();
  const userInfo = useSelector((state: any) => state?.user?.currentUser);
  const isDeletePermission =
    userInfo?.role === "admin" || userInfo?._id === post?.user?._id;

  const [showComments, setShowComments] = useState<boolean>(false);
  const [comments, setComments] = useState<string[]>([]);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [likeState, setLikeState] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const createCommentPost = () => {
    setShowInput(true);
    handleShowComments();
  };

  const createLikePost = () => {
    if (likeState) {
      dispatch(unlikePost(post?._id));
      setLikeState(false);
    } else {
      dispatch(likePost(post?._id));
      setLikeState(true);
    }
  };

  const deletePost = async (postId: string) => {
    const result = await dispatch(dispatchDeletePost(postId)).unwrap();
    console.log("🚀 ~ file: Post.tsx:48 ~ deletePost ~ result:", result);
    if (result === 200) {
      toast("Xóa bài viết thành công");
    }
  };

  const handleShowComments = async () => {
    setShowComments(true);
    const { data } = await dispatch(getCommentByPost(post?._id)).unwrap();

    setComments(data);
  };

  return (
    <div
      className={`myHomeContainer relative flex flex-col gap-y-4 lg:gap-y-6`}
    >
      <div className="user-info flex items-center gap-x-5">
        <img
          src={post?.user?.avatar}
          alt="Khoa's crush"
          className="h-[40px] w-[40px] rounded-full object-cover"
        />
        <div className="">
          <p className="font-bold">{post?.user?.fullName}</p>
          <p className="text-sm font-light text-gray-600">
            {moment(post?.createdAt).fromNow()}
          </p>
        </div>
        {isDeletePermission && (
          <div className="relative ml-auto mr-0 flex items-center gap-x-2">
            <AiOutlineDelete
              className="cursor-pointer lg:text-xl"
              onClick={() => setShowConfirm((prev) => !prev)}
            />
            {/* <IoMdClose className="cursor-pointer lg:text-2xl" /> */}
            <div
              className={`absolute right-0 top-[calc(100%+6px)] z-20 w-52 rounded bg-white p-2 shadow-covered transition-all duration-300 ${
                showConfirm ? "" : "invisible -translate-y-1/2 opacity-0"
              }`}
            >
              <i className="select-none text-center text-sm">
                Bài đăng sẽ bị xoá vĩnh viễn.
              </i>
              <div className="mt-2 grid grid-cols-2 gap-x-2">
                <button
                  className="z-10 rounded-md bg-sky-400 px-2 py-1 text-white transition-colors duration-150 hover:bg-sky-500 "
                  onClick={() => setShowConfirm(false)}
                >
                  Huỷ
                </button>
                <button
                  className="z-10 rounded-md bg-red-400 px-2 py-1 text-white transition-colors duration-150 hover:bg-red-600 "
                  onClick={() => {
                    deletePost(post._id);
                    setShowConfirm(false);
                  }}
                >
                  Xác nhận
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {post?.image && (
        <div className="aspect-square w-full">
          <img
            src={post?.image}
            alt="Khoa's crush"
            className="w-full rounded-md object-cover"
          />
        </div>
      )}
      {post?.content && (
        <div dangerouslySetInnerHTML={{ __html: post?.content }}></div>
      )}
      <div className="react">
        <div className="react-quantity mb-2 flex items-center justify-between">
          <p className="like-quantity flex items-center gap-1">
            <AiFillHeart className="text-lg text-red-500" />
            {post?.likes.length}
          </p>
          <p
            className="comment-quantity flex max-w-[70%] cursor-pointer items-center gap-1 text-end hover:italic hover:underline"
            onClick={handleShowComments}
          >
            {post.totalComment === 0 || !post?.totalComment ? (
              <i className="">Hãy là người đầu tiên bình luận bài viết này</i>
            ) : (
              <span>
                Xem tất cả{" "}
                {comments.length > 0 ? comments.length : post.totalComment} bình
                luận
              </span>
            )}
            {/* <span>Xem tất cả bình luận</span> */}
          </p>
        </div>

        <div className="react-action grid grid-cols-2 border-b border-t p-0.5  text-base">
          <button
            className="like-action rounded-md p-1.5 capitalize hover:bg-gray-200"
            onClick={createLikePost}
          >
            {likeState ? (
              <span className="flex items-center justify-center gap-x-2 text-red-500">
                <IoHeart
                  size={20}
                  className="wiggle"
                />
                liked
              </span>
            ) : (
              <span className="flex items-center justify-center gap-x-2">
                <IoHeartOutline />
                like
              </span>
            )}
          </button>
          <button
            className="comment-action rounded-md p-1.5 capitalize hover:bg-gray-200"
            onClick={createCommentPost}
          >
            <span
              className={`flex items-center justify-center gap-x-2 ${
                showComments ? "text-sky-500" : ""
              }`}
            >
              {showComments ? (
                <TbMessageCircle2Filled size={20} />
              ) : (
                <LuMessageCircle size={20} />
              )}
              Bình luận
            </span>
          </button>
        </div>
      </div>
      {showComments ? (
        <Comments
          comments={comments}
          reload={handleShowComments}
        />
      ) : null}

      {/* <p
        className="cursor-pointer font-semibold hover:underline"
        onClick={() => console.log("see more...")}
      >
        Xem thêm...
      </p> */}
      {showInput ? (
        <InputComment
          postId={post?._id}
          avatar={userInfo.avatar}
          reload={handleShowComments}
          focus={showInput}
        />
      ) : null}
    </div>
  );
};

export default memo(Post);
