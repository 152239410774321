import { useEffect, useState } from "react";
import ManageMenu from "components/manageMenu";
import MakeProgressAutoClickSpamADS from "./MakeProgressAutoClickSpamADS";
import OrderdiaryAutoClickSpamADS from "./OrderdiaryAutoClickSpamADS";
import { LOCAL_STORAGE } from "helper/storage.helper";
const BuffAutoClickSpamADS = () => {
  const [active, setActive] = useState<number>(1);
  const [userInfo, setUserInfo] = useState<any>();
  const renderComponent = (value: number) => {
    switch (value) {
      case 1:
        return <OrderdiaryAutoClickSpamADS setActive = {setActive}/>;
      case 2:
        return <MakeProgressAutoClickSpamADS setActive = {setActive}/>;
      default:
        return null;
    }
  };
  useEffect(() => {
    const result = LOCAL_STORAGE.getCurrentUser();
    setUserInfo(result);
  }, []);
  return (
    <div className="p-4">
      <div className="text-cyan-700 font-bold text-lg">
        TẠO NHIỆM VỤ AUTO CLICK SPAM ADS
      </div>
      <ManageMenu active={active} setActive={setActive} />

      <div>{renderComponent(active)}</div>
    </div>
  );
};

export default BuffAutoClickSpamADS;
