import moment from "moment";
import { useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { RxAvatar, RxPaperPlane } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteComment,
  updateComment,
} from "redux/postCommunity/postCommunity.slice";

const Comment = ({ data, reload }: any) => {
  const userInfo = useSelector((state: any) => state?.user?.currentUser);
  const dispatch = useDispatch<any>();
  const [updateInput, setUpdateInput] = useState<string>(data.content);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showAction, setShowAction] = useState<boolean>(false);

  const handleDeleteComment = async (commentId: string, postId: string) => {
    await dispatch(
      deleteComment({ cmtId: commentId, postId: postId }),
    ).unwrap();
    reload();
    setShowAction(false);
  };
  const handleShowUpdateComment = () => {
    setShowInput(true);
    setShowAction(false);
  };

  const handleUpdateComment = (cmtId: string) => {
    if (updateInput === "") {
      toast("Bạn vui lòng nhập nội dung bình luận");
      return;
    }
    dispatch(updateComment({ cmtId: cmtId, content: updateInput })).unwrap();
    setShowInput(false);
  };

  const handleEnterKeyPress = (event: any, cmtId: string) => {
    if (event.key === "Enter") {
      handleUpdateComment(cmtId);
    }
  };

  const handleShowAction = () => {
    if (userInfo.role === "admin" || userInfo._id === data?.user._id) {
      setShowAction((prev) => !prev);
    }
  };

  return (
    <div className="comment">
      <div className="flex w-full gap-x-2">
        <div className="h-10 w-10 flex-shrink-0 rounded-full">
          {data.user.avatar ? (
            <img
              src={data.user.avatar}
              alt="user-avatar"
              className="h-10 w-10 rounded-full object-cover"
            />
          ) : (
            <RxAvatar
              size={40}
              className=""
            />
          )}
        </div>
        <div className="group relative rounded-lg bg-gray-100 p-3 pr-6 pt-1">
          <p className="font-bold">{data.user.userName}</p>
          <div className="w-full overflow-hidden">
            {showInput ? (
              <form
                className="relative flex flex-1 items-center rounded-md bg-white px-1 py-0.5"
                onSubmit={() => handleUpdateComment(data._id)}
              >
                <input
                  type="text"
                  className="w-full bg-inherit text-sm outline-none"
                  value={updateInput}
                  onChange={(e) => setUpdateInput(e.target.value)}
                  onKeyPress={(e) => handleEnterKeyPress(e, data._id)}
                />
                <button type="submit">
                  <RxPaperPlane
                    size={16}
                    className="ml-1 cursor-pointer hover:text-sky-600"
                  />
                </button>
              </form>
            ) : (
              <>
                <p className="truncate">{updateInput}</p>
                <p className="text-sm font-light text-gray-600">
                  {moment(data?.createdAt).fromNow()}
                </p>
              </>
            )}
          </div>

          {showInput ? (
            <button
              className="absolute right-1 top-1"
              onClick={() => setShowInput(false)}
            >
              <IoMdCloseCircleOutline size={20} />
            </button>
          ) : (
            <div
              className="react absolute right-1 top-1 cursor-pointer rounded-xl border bg-white p-1 transition-all duration-150 group-hover:visible lg:invisible"
              onClick={handleShowAction}
            >
              <CiMenuKebab />
            </div>
          )}

          <div
            className={`action absolute right-0 top-0 z-10 -translate-y-full flex-col overflow-hidden rounded bg-white shadow transition-all duration-200 ${
              showAction ? "flex" : "hidden"
            }`}
          >
            <button
              className="inline-block px-1.5 py-0.5 hover:bg-sky-300 hover:text-white"
              onClick={handleShowUpdateComment}
            >
              Sửa
            </button>
            <button
              className="inline-block px-1.5 py-0.5 hover:bg-sky-300 hover:text-white"
              onClick={() => handleDeleteComment(data._id, data.post)}
            >
              Xoá
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
