import { ApiInstanceTraffic } from "helper/api.helper";

export const autoClickSpamAdsAPI = {
  createAutoSpamAds,
  getPaggingAutoSpamAdsByUser,
  getInfoTagsList,
  runMissionAutoSpamAds,
  pauseMissionAutoSpamAds,
  deleteOneMissionAutoSpamAds,
  deleteManyMissionAutoSpamAds,
  handleAcceptedMissionAutoSpamAds,
  getPaggingMissionAutoSpamAds,
  deleteMutipleAutoSpamAds,
  handleRunMissionAutoSpamAds,
};

function createAutoSpamAds(body: any) {
  const url = `auto-spam-ads`;
  return ApiInstanceTraffic().post(url, body);
}

function getPaggingAutoSpamAdsByUser(query: {
  pageIndex: number;
  pageSize: number;
  search: string;
  statusFilter: number[];
}) {
  const url = `auto-spam-ads?pageIndex=${query.pageIndex}&pageSize=${query.pageSize}&search=${query.search}&statusFilter=${JSON.stringify(query.statusFilter)}`;
  return ApiInstanceTraffic().get(url);
}

function getInfoTagsList() {
  const url = `auto-spam-ads/count-tags`;
  return ApiInstanceTraffic().get(url);
}

function runMissionAutoSpamAds(id: any) {
  const url = `auto-spam-ads/${id}/run`;
  return ApiInstanceTraffic().put(url);
}

function pauseMissionAutoSpamAds(id: any) {
  const url = `auto-spam-ads/${id}/pause`;
  return ApiInstanceTraffic().put(url);
}

function deleteOneMissionAutoSpamAds(id: any) {
  const url = `auto-spam-ads/${id}`;
  return ApiInstanceTraffic().delete(url);
}

function deleteManyMissionAutoSpamAds(query: any) {
  const url = `auto-spam-ads/deleteMany`;
  return ApiInstanceTraffic().delete(url, { params: query });
}

function getPaggingMissionAutoSpamAds(query: any) {
    const url = `auto-spam-ads/getPagging?pageIndex=${query.pageIndex}&pageSize=${query.pageSize}&search=${query.search}`;
    return ApiInstanceTraffic().get(url);
}

function handleAcceptedMissionAutoSpamAds(id: string, mission: string) {
    const url = `auto-spam-ads/${id}/${mission}`;
    return ApiInstanceTraffic().put(url);
}

function handleRunMissionAutoSpamAds(id: string, data: any) {
    const url = `auto-spam-ads/${id}/run`;
    return ApiInstanceTraffic().put(url, data);
}

function deleteMutipleAutoSpamAds(arrId: string[]) {
  const query: string = arrId.reduce((accumulator, currentValue) => {
    return accumulator + `selectedId=${currentValue}` + "&";
  }, "");
  const url = `auto-spam-ads/deleteMany?${query.slice(0, -1)}`;
  return ApiInstanceTraffic().delete(url, { params: arrId });
}

