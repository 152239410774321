import { ApiInstance } from "helper/api.helper";

export const actionsHistorymAPI = {
  createActionsHistory,
  getList,
};

function createActionsHistory(body: any) {
  const url = `action-history/create`;
  return ApiInstance().post(url, body);
}
function getList(query: any) {
  const url = `action-history/getPaggingActionHistory`;
  return ApiInstance().get(url, { params: query });
}
