import { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload";

export const FormatNumberVND = (value: any) => {
  return value?.toLocaleString("vi", { style: "currency", currency: "VND" });
};
// pending', 'newRedo', 'approved', 'error'
export const renderStatus = (value: string) => {
  switch (value) {
    case "pending":
      return "Chờ duyệt";
    case "newRedo":
      return "Cần làm lại";
    case "approved":
      return "Đã duyệt";
    case "error":
      return "Báo lỗi";
    default:
      return null;
  }
};

export const OptionStatus = [
  { value: "", label: "Tất cả" },
  { value: "pending", label: "Chờ duyệt" },
  { value: "newRedo", label: "Cần làm lại" },
  { value: "approved", label: "Đã duyệt" },
  { value: "error", label: "bị lỗi" },
];

export const statusTraffic = (value: number) => {
  switch (value) {
    case 0:
      return "Chờ duyệt";
    case 1:
      return "Đã duyệt"; // đã duyệt nhưng chưa chạy
    case 2:
      return "Đang chạy";
    case 3:
      return "Tạm ngưng";
    case 4:
      return "Đã ngừng";
    case 5:
      return "Hoàn thành";
    default:
      return null;
  }
};

// functions for Upload img

const onHandleChange = (
  fileList: UploadFile<any>[],
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>,
) => {
  setFileList(
    fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    }),
  );
};

const onHandlePreview = async (file: UploadFile<any>) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export { onHandleChange, onHandlePreview };