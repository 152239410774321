import {
    AiOutlineEye,
    AiOutlineEyeInvisible,
    AiOutlineKey,
} from "react-icons/ai";
import { Modal } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import PrimaryButton from "components/ui/button/PrimaryButtton";
import SecondaryButton from "components/ui/button/SecondaryButton";
import { userAction } from "redux/user/user.slice";
import { toast } from "react-toastify";
import FullPageSpiner from "components/ui/spiner/FullPageSpiner";
import { sendLogs } from "common/function";
import { TypeAction, TypeGroup } from "common/constant";

function SetPasswordModal(props: {
    showButton?: any;
    isModalOpen: boolean;
    setIsModalOpen: Function;
    memberType: number;
}) {
    const dispatch = useDispatch<any>();
    const [isShowPass, setShowPass] = useState(false);
    const userInfo = useSelector((state: any) => state.user.updateUser) || {};
    const [isLoading, setIsLoading] = useState(false);

    const setPassword: any = {
        0: (payload: any) => dispatch(userAction.setPassword(payload)),
        1: (payload: any) => dispatch(userAction.setAdminPassword(payload)),
    };

    const handleCancel = () => {
        formik.setValues(formik.initialValues);
        formik.touched.confirmPassword = false;
        formik.touched.newPassword = false;

        props.setIsModalOpen(false);
    };

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required("Required")
                .matches(
                    /[A-Za-z0-9\d]{6,}$/,
                    "Password must be minimum six characters"
                ),
            confirmPassword: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
        }),
        onSubmit: (values) => {
            setIsLoading(true);
            const result = setPassword[props.memberType]({
                id: userInfo._id,
                userIdCDS: userInfo?.userIdCDS,
                ...values,
            });
            result.then((data: any) => {
                if (data.error) {
                    toast.error(data?.error?.message || "Update fail");
                    setIsLoading(false);
                    return;
                }
                toast.success("Update successfully!");
                setIsLoading(false);
                handleCancel();
                const options = {
                    userInfo
                }
                sendLogs(
                    TypeGroup[1], 
                    TypeAction[2],
                    options
                );
            });
        },
    });

    return (
        <>
            {props?.showButton}
            <Modal
                title={""}
                open={props.isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <div className="relative py-3">
                    <FullPageSpiner isLoading={isLoading} />
                    <form
                        className={`${isLoading ? "opacity-0" : ""}`}
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="w-full mb-6">
                            <div className="relative w-full mb-6">
                                <label
                                    htmlFor="newPassword"
                                    className="font-semibold mb-1"
                                >
                                    Mật khẩu mới{" "}
                                    {formik.errors.newPassword &&
                                        formik.touched.newPassword && (
                                            <i className="text-sm text-red-500">
                                                {formik.errors.newPassword.toString()}
                                            </i>
                                        )}
                                </label>
                                <input
                                    aria-label="input"
                                    className="text-gray-500 border border-gray-300 rounded-md w-full px-3 py-2"
                                    onChange={formik.handleChange}
                                    value={formik.values.newPassword}
                                    type={`${isShowPass ? "text" : "password"}`}
                                    id="newPassword"
                                    name="newPassword"
                                ></input>
                                <button
                                    className="absolute right-2 bottom-2"
                                    type="button"
                                    onClick={() => setShowPass(!isShowPass)}
                                >
                                    {isShowPass ? (
                                        <AiOutlineEyeInvisible size={20} />
                                    ) : (
                                        <AiOutlineEye size={20} />
                                    )}
                                </button>
                            </div>

                            <div className="w-full mb-6">
                                <label
                                    htmlFor="confirmPassword"
                                    className="font-semibold mb-1"
                                >
                                    Nhập lại mật khẩu{" "}
                                    {formik.errors.confirmPassword &&
                                        formik.touched.confirmPassword && (
                                            <i className="text-sm text-red-500">
                                                {formik.errors.confirmPassword.toString()}
                                            </i>
                                        )}
                                </label>
                                <input
                                    aria-label="input"
                                    id="confirmPassword"
                                    type={`${isShowPass ? "text" : "password"}`}
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    className="text-gray-500 border border-gray-300 rounded-md w-full px-3 py-2"
                                    name="confirmPassword"
                                ></input>
                            </div>
                        </div>

                        <div className="flex justify-end gap-2">
                            <PrimaryButton
                                type="submit"
                                content="Cập nhật"
                                icon={<AiOutlineKey className="text-xl me-2" />}
                            />
                            <SecondaryButton
                                content="Hủy"
                                type="button"
                                onClick={handleCancel}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
}
export default SetPasswordModal;
