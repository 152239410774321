import { Modal } from "antd";
interface IProps {
    isModel: any;
    handleOk: any;
    handleCancel: any;
    error: any
}
const ModalErrorJob = (props: IProps) => {
    const { isModel, handleOk, handleCancel, error } = props;
    return (
        <Modal
            open={isModel}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            title="Nội dung lỗi của job này"
        >
            <div className="justify-center h-32 items-end text-3xl text-red-600 mb-5 font-semibold overflow-auto">
                {error}
            </div>

        </Modal>
    );
};

export default ModalErrorJob;