import { actionsHistorymAPI } from "api/actions-history.api";
import { LOCAL_STORAGE } from "helper/storage.helper";
import {
  TypeGroup,
  TypeAction
} from "./constant"
function toSlug(str: string) {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
}

function validateIpAddress(ipAddress: string) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAddress,
    )
  ) {
    return true;
  }
  return false;
}

function validateJson(text: string) {
  return /^[\],:{}\s]*$/.test(
    text
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]",
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, ""),
  );
}

// const ListTypeGroup = [
//     "LOGIN",
//     "MANAGE_MEMBER",
//     "MANAGE_SECTORS",
//     "MANAGE_POSTS",
//     "MANAGE_NOTIFICATION_CDS",
//     "MANAGE_WITHDRAW",
//     "MANAGE_PAYMENT_CDS",
//     "MANAGE_YOUTUBE_JOB",
//     "MANAGE_TWITTER_JOB",
//     "MANAGE_FACEBOOK_JOB",
//     "MANAGE_GOOGLE_JOB",
//     "MANAGE_GOOGLE_MAP_JOB",
//     "MANAGE_INSTAGRAM_JOB",
//     "MANAGE_PROXY",
//     "MANAGE_TRAFFIC",
//     "MANAGE_AUTO_CLICK_SPAM",
//     "MANAGE_AUTO_CLICK_SPAM_ADS",
// ]

const handleContent = (typeGroup: string, typeAction: string, options: any) => {
  const AdminInfo = LOCAL_STORAGE.getCurrentUser();
  switch (typeGroup) {
    case "LOGIN":
      return `<div><b>${AdminInfo?.userName}</b> vừa đăng nhập</div>`;
    case "MANAGE_MEMBER":
      const { userInfo, credit } = options;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhập thông tin người dùng <b>${userInfo?.userName}</b></div>`;
      if (typeAction === "UPDATE_CREDIT")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhập credit của người dùng <b>${userInfo?.userName}</b> từ <b>${userInfo?.credit}</b> thành <b>${credit}</b></div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa người dùng <b>${userInfo?.userName}</b></div>`;
      break;
    case "MANAGE_SECTORS":
      const { sectorName } = options;
      if (typeAction === "ADD")
        return `<div><b>${AdminInfo?.userName}</b> đã thêm mới danh mục <b>${sectorName}</b></div>`;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhật thông tin cho danh mục <b>${sectorName}</b></div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa danh mục <b>${sectorName}</b></div>`;
      break;
    case "MANAGE_POSTS":
      const { title, contentReport, typeNumber } = options;
      if (typeAction === "APPROVE")
        return `<div><b>${AdminInfo?.userName}</b> đã phê duyệt bài viết <b>${title}</b></div>`;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhật trạng thái của báo cáo '${contentReport}'</div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa ${typeNumber === 1
          ? `bài viết <b>${title}</b>`
          : `báo cáo bài viết '${contentReport}'`
          } </div>`;
      break;
    case "MANAGE_NOTIFICATION_CDS":
      const { status, content } = options;
      if (typeAction === "ADD")
        return `<div><b>${AdminInfo?.userName}</b> đã ${status} thông báo mới đến người dùng congdongseo</div>`;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã chỉnh sửa thông báo '${content}...' gửi đến thành viên congdongseo</div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa thông báo '${content}...' </div>`;
      break;
    case "MANAGE_WITHDRAW":
      const { userName } = options;
      if (typeAction === "APPROVE")
        return `<div><b>${AdminInfo?.userName}</b> đã phê duyệt yêu cầu rút tiền của người dùng <b>${userName}</b></div>`;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã từ chối yêu cầu rút tiền của người dùng <b>${userName}</b></div>`;
      break;
    case "MANAGE_PAYMENT_CDS":
      const {
        userNameBuy,
        paymentContent,
        beforeCreadit = "",
        afterCredit = "",
      } = options;
      if (typeAction === "APPROVE")
        return `<div><b>${AdminInfo?.userName}</b> đã chuyển credit cho người dùng <b>${userNameBuy}</b> với nội dung mua <b>${paymentContent}</b></div>`;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã thay đổi credit cho người dùng <b>${userNameBuy}</b></div>`;
      if (typeAction === "UPDATE_CREDIT")
        return `<div><b>${AdminInfo?.userName}</b> đã thay đổi credit cho người dùng <b>${userNameBuy}</b> và Credit muốn mua từ <b>${beforeCreadit}</b> thành <b>${afterCredit}</b></div>`;
      break;
    //Admin thanh toán tiền cho user làm nhiệm vụ youtube
    // typeTask === 1 for subcribe, // typeTask === 2 for comment, 
    case TypeGroup[7]:
      if (typeAction === TypeAction[4]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Subcribe kênh Youtube: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Comment kênh Youtube: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
      }
      if (typeAction === TypeAction[6]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ Subcribe kênh Youtube: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ Comment kênh Youtube: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
      }
      break;
    case TypeGroup[8]:
      if (typeAction === TypeAction[4]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ follow kênh Twitter: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Comment bài viết Twitter: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
      }
      // typeTask === 1 for subcribe, // typeTask === 2 for comment, 
      if (typeAction === TypeAction[6]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ follow kênh Twitter: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ Comment bài viết Twitter: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
      }
      break;
    case TypeGroup[9]:
      if (typeAction === TypeAction[4]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ follow Facebook: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ comment bài viết Facebook: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
      }
      // typeTask === 1 for subcribe, // typeTask === 2 for comment, 
      if (typeAction === TypeAction[6]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ follow Facebook: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ comment bài viết Facebook: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
      }
      break;
    case TypeGroup[10]: {
      if (typeAction === TypeAction[4]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Tăng lượt tìm kiếm bằng Google: <b>${options?.data?.worker?.link}</b> với từ khóa tìm kiếm: <b>${options?.data?.worker?.keyWord}</b> <br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
        if (options?.data?.worker?.typeTask === 4) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Truy cập trang web: <b>${options?.data?.worker?.link}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
      }
      break;

    }
    case TypeGroup[11]: {
      if (typeAction === TypeAction[6]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ Review Google Map: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> chưa thực hiện nhiệm vụ Review Google Map kèm hình ảnh: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b> và hình ảnh: ${options?.data?.worker?.image}<br /> Tài khoản <b>${AdminInfo?.userName}</b> đã yêu cầu tài khoản <b>${options?.data?.user?.userName}</b> làm lại.</div>`;
        }
      }
      if (typeAction === TypeAction[4]) {
        if (options?.data?.worker?.typeTask === 1) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Review Google Map: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b><br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
        if (options?.data?.worker?.typeTask === 2) {
          return `<div>Tài khoản <b>${options?.data?.user?.userName}</b> đã thực hiện nhiệm vụ Review Google Map kèm hình ảnh: <b>${options?.data?.worker?.link}</b> với nội dung <b>${options?.data?.worker?.comment}</b> và hình ảnh: ${options?.data?.worker?.image}<br /> Tài khoản <b>${AdminInfo?.userName}</b> đã chấp nhận thanh toán cho tài khoản <b>${options?.data?.user?.userName}</b> với giá <b>${options?.data?.worker?.money}đ</b></div>`;
        }
      }
      break;

    }
    case "MANAGE_TRAFFIC":
      const { record } = options;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhập traffic của domain <b>${record.domain}</b></div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa traffic của domain <b>${record.domain}</b></div>`;
      if (typeAction === "ADD")
        return `<div><b>${AdminInfo?.userName}</b> đã thêm mới một traffic có domain <b>${record.domain}</b></div>`;
      break;

    case "MANAGE_AUTO_CLICK_SPAM": {
      const { record } = options;
      if (typeAction === "UPDATE") {

        return `<div><b>${AdminInfo?.userName}</b> đã cập nhập auto click spam của domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      }
      if (typeAction === "DELETE") {

        return `<div><b>${AdminInfo?.userName}</b> đã xóa auto click spam của domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      }
      if (typeAction === "ADD") {

        return `<div><b>${AdminInfo?.userName}</b> đã thêm mới một auto click spam có domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      }
      break;
    }

    case "MANAGE_AUTO_CLICK_SPAM_ADS": {
      const { record } = options;
      if (typeAction === "UPDATE")
        return `<div><b>${AdminInfo?.userName}</b> đã cập nhập click spam ADS của domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      if (typeAction === "DELETE")
        return `<div><b>${AdminInfo?.userName}</b> đã xóa click spam ADS của domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      if (typeAction === "ADD")
        return `<div><b>${AdminInfo?.userName}</b> đã thêm mới một click spam ADS có domain <b>${record.domain}</b>, nhiệm vụ <b>${record.name}</b></div>`;
      break;
    }
  }
}

const sendLogs = async (
  typeGroup: string,
  typeAction: string,
  options: any,
) => {
  const obj = {
    content: handleContent(typeGroup, typeAction, options),
    typeGroup,
    typeAction,
  };
  await actionsHistorymAPI.createActionsHistory(obj);
};

export { toSlug, validateIpAddress, validateJson, sendLogs }
