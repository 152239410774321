import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import PrimaryButton from "components/ui/button/PrimaryButtton";
import { GoSearch } from "react-icons/go";
import { useState, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { SlControlStart } from "react-icons/sl";
import { AiFillSetting, AiOutlinePause } from "react-icons/ai";
import { PiStopCircle, PiTrashLight } from "react-icons/pi";
import { BiSolidLeftArrow } from "react-icons/bi";
import { BsCheck2All } from "react-icons/bs";
import { LuDelete } from "react-icons/lu";
import { toast } from "react-toastify";
import { statusTraffic } from "../../../util";
import { FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { autoClickSpamAPI } from "api/auto-click-spam.api";
import { sendLogs } from "common/function";
import { TypeAction, TypeGroup } from "common/constant";

const initForm = { proxy: "all", thread: 0 };

const ManageAutoClickSpam = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDeleteArrOpen, setIsModalDeleteArrOpen] = useState(false);
  const [isModalStartOpen, setIsModalStartOpen] = useState(false);
  const [render, setRender] = useState(false);
  const [idMission, setIdMission] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [record, setRecord] = useState<any>({});
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isSwitchChange, setIsSwitchChange] = useState<boolean>(false);

  const [selectedMission, setSelectedMission] = useState<any>();

  const [form, setForm] = useState<any>(initForm);
  const userInfo = useSelector((state: any) => state.user.currentUser);

  const handleShowModalAccept = (id: string) => {
    setIdMission(id);
    setIsModalOpen(true);
  };

  const handleChangeType = () => {
    setIsSwitchChange(!isSwitchChange);
  };

  const handleCancelAccept = () => {
    setIsModalOpen(false);
    setIdMission("");
  };

  const handleShowModalDelete = (id: string, record: any) => {
    setIdMission(id);
    setRecord(record);
    setIsModalDeleteOpen(true);
  };

  const handleModalDeleteCancel = () => {
    setIsModalDeleteOpen(false);
    setIdMission("");
    setRecord({});
  };

  const handleShowModalDeleteArr = () => {
    setIsModalDeleteArrOpen(true);
  };

  const handleModalDeleteArrCancel = () => {
    setIsModalDeleteArrOpen(false);
  };

  const handleSelectMission = (mission: any) => {
    setSelectedMission(mission);
    setIsModalStartOpen(true);
  };

  const getPageMissionAutoSpam = async () => {
    const query = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      search: search,
    };

    const response: any =
      await autoClickSpamAPI.getPaggingMissionAutoSpam(query);
    setData(response.result.data);
    setTotalPage(response?.result.totalDoc);
  };

  const missionAutoSpam = async (id: string, mission: string, record: any) => {
    const response: any = await autoClickSpamAPI.handleAcceptedMissionAutoSpam(
      id,
      mission,
    );
    if (response.status === 200 && response.message === "Success") {
      setRender(!render);
      sendLogs(TypeGroup[15], TypeAction[2], { record });
    }
    if (response.status === 400) {
      toast.error(response.message);
    }
  };

  const handleSearch = () => {
    getPageMissionAutoSpam();
  };

  const handleAcceptStatus = async (id: string, mission: string) => {
    setIsModalOpen(false);
    const response: any = await autoClickSpamAPI.handleAcceptedMissionAutoSpam(
      id,
      mission,
    );
    if (response.status === 200 && response.message === "Success") {
      setRender(!render);
      setSelectedId([]);
      setSelectedRecords([]);
    }
  };

  const handleDeleteMissionById = async (id: string) => {
    setIsModalDeleteOpen(false);
    const response: any = await autoClickSpamAPI.deleteOneMissionAutoSpam(id);
    if (response.status === 200 && response.message === "Success") {
      setRender(!render);
      sendLogs(TypeGroup[15], TypeAction[3], { record });
      toast.success(response.message);
    }
  };

  const handleDeleteMutipleMission = async (
    selectedId: string[],
    selectedRecords: any[],
  ) => {
    setIsModalDeleteArrOpen(false);
    const response: any =
      await autoClickSpamAPI.deleteMutipleAutoSpam(selectedId);
    if (response.status === 200 && response.message === "Success") {
      for (const record of selectedRecords) {
        sendLogs(TypeGroup[15], TypeAction[3], { record });
      }
      setRender(!render);
      toast.success(response.message);
      setSelectedRowKeys([]);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any,
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const arrId: string[] = [];
    const arrRecord: any[] = [];
    selectedRows.map((item: any) => {
      const id = item._id;
      arrId.push(id);
      arrRecord.push(item);
    });
    setSelectedId(arrId);
    setSelectedRecords(arrRecord);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleRunTraffic = async () => {
    try {
      const res: any = await autoClickSpamAPI.handleRunMissionAutoSpam(
        selectedMission?._id,
        form,
      );
      if (res.status === 200) {
        sendLogs(TypeGroup[15], TypeAction[2], { record: selectedMission });
        setRender(!render);
        toast.success(res.message);
        setIsModalStartOpen(false);
        setSelectedMission(null);
        setForm(initForm);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPageMissionAutoSpam();
  }, [pageIndex, pageSize, render]);

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Tài khoản",
      dataIndex: "user",
      key: "user",
      width: 120,
      render: (value) => value.fullName,
    },
    {
      title: "Tên nhiệm vụ",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (value) => value,
      ellipsis: true,
    },
    {
      title: "URL của kênh",
      key: "domain",
      dataIndex: "domain",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Từ khóa",
      key: "keyword",
      dataIndex: "keyword",
      render: (value) => value,
      ellipsis: true,
      width: 120,
    },
    {
      title: "Gói auto click spam",
      key: "originQuantity",
      dataIndex: "originQuantity",
      align: "center",
      width: 120,
      render: (value): any => {
        return (
          <div>{new Intl.NumberFormat("en").format(value)} traffic/ngày</div>
        );
      },
    },
    {
      title: "Traffic mục tiêu",
      dataIndex: "totalTraffic",
      key: "totalTraffic",
      width: 100,
      align: "center",
      render: (text, record, index) => new Intl.NumberFormat("en").format(text),
      sorter: (a, b) => a?.totalTraffic - b?.totalTraffic,
    },
    {
      title: "Traffic theo ngày",
      dataIndex: "originQuantity",
      key: "originQuantity",
      width: 100,
      align: "center",
      render: (text, record, index) => new Intl.NumberFormat("en").format(text),
      sorter: (a, b) => a?.originQuantity - b?.originQuantity,
    },
    {
      title: (
        <div className="flex">
          <div>Traffic đã làm</div>
          <Tooltip title={`${isSwitchChange ? "phần trăm" : "kiểu số"}`}>
            <Switch
              size="small"
              className="hello my-auto ml-2"
              onChange={handleChangeType}
            />
          </Tooltip>
        </div>
      ),
      key: "curentQuantity",
      dataIndex: "curentQuantity",
      align: "center",
      width: 120,
      render: (value, record) => {
        return isSwitchChange ? (
          <div>{((value * 100) / record?.totalTraffic).toFixed(2)}%</div>
        ) : (
          <div>{new Intl.NumberFormat("en").format(value)}</div>
        );
      },
    },
    {
      title: "Tình trạng",
      key: "status",
      dataIndex: "status",
      align: "center",
      width: 120,
      render: (value) => {
        if (value === 0) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-orange-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === 1) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-green-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === 2) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-blue-400 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === 3) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-yellow-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === 4) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-yellow-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === 5) {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-green-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
        if (value === "error") {
          return (
            <div className="text-center">
              <span className="whitespace-nowrap rounded-lg bg-red-500 px-2 py-1 text-center text-white">
                {statusTraffic(value)}
              </span>
            </div>
          );
        }
      },
    },
    {
      title: "Ngày thực hiện",
      key: "startDate",
      dataIndex: "startDate",
      align: "center",
      render: (value) => (
        <div className="text-center">{moment(value).format("DD/MM/YYYY")}</div>
      ),
      sorter: (a: any, b: any) =>
        Date.parse(a.startDate) - Date.parse(b.startDate),
      width: 110,
    },
    {
      title: "Ngày kết thúc",
      key: "endDate",
      dataIndex: "endDate",
      align: "center",
      render: (value) => (
        <div className="text-center">{moment(value).format("DD/MM/YYYY")}</div>
      ),
      sorter: (a: any, b: any) => Date.parse(a.endDate) - Date.parse(b.endDate),
      width: 110,
    },

    {
      title: "Hoạt động",
      key: "",
      dataIndex: "",
      align: "center",
      width: 150,
      render: (value, record) => {
        return userInfo.role === "sales" ? (
          <></>
        ) : (
          <>
            {value.status === 5 ? (
              <Button className="border border-green-500 font-bold text-green-500">
                Hoàn thành
              </Button>
            ) : (
              <div className="flex items-center justify-center gap-x-4">
                {value.status === 0 && (
                  <Tooltip title={"Phê duyệt"}>
                    <BsCheck2All
                      fill="green"
                      className="cursor-pointer"
                      size={24}
                      onClick={() => handleShowModalAccept(value._id)}
                    />
                  </Tooltip>
                )}
                {value.status === 1 && (
                  <Tooltip title={"Cấu hình proxy"}>
                    <AiFillSetting
                      size={24}
                      fill="green"
                      className="rotate-180 cursor-pointer"
                      onClick={() => handleSelectMission(value)}
                    />
                  </Tooltip>
                )}
                {value.status === 2 && (
                  <>
                    <Tooltip title={"Tạm ngưng"}>
                      <AiOutlinePause
                        size={24}
                        fill="green"
                        className="cursor-pointer"
                        onClick={() =>
                          missionAutoSpam(value._id, "pause", record)
                        }
                      />
                    </Tooltip>
                    <Tooltip title={"Ngừng nhiệm vụ"}>
                      <PiStopCircle
                        size={24}
                        fill="red"
                        className="cursor-pointer"
                        onClick={() =>
                          missionAutoSpam(value._id, "stop", record)
                        }
                      />
                    </Tooltip>
                  </>
                )}
                {value.status === 3 && (
                  <>
                    <Tooltip title={"Tiếp tục"}>
                      <BiSolidLeftArrow
                        size={24}
                        fill="green"
                        className="rotate-180 cursor-pointer"
                        onClick={() =>
                          missionAutoSpam(value._id, "run", record)
                        }
                      />
                    </Tooltip>
                    <Tooltip title={"Ngừng nhiệm vụ"}>
                      <PiStopCircle
                        size={24}
                        fill="red"
                        className="cursor-pointer"
                        onClick={() =>
                          missionAutoSpam(value._id, "stop", record)
                        }
                      />
                    </Tooltip>
                  </>
                )}
                {value.status === 4 && (
                  <Tooltip title={"Chạy lại nhiệm vụ"}>
                    <SlControlStart
                      size={18}
                      fill="green"
                      className="rotate-180 cursor-pointer"
                      onClick={() => missionAutoSpam(value._id, "run", record)}
                    />
                  </Tooltip>
                )}
                <Tooltip title={"Xóa nhiệm vụ"}>
                  <PiTrashLight
                    size={25}
                    className="cursor-pointer"
                    onClick={() => handleShowModalDelete(value._id, record)}
                  />
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div
      className="p-4"
      id="manage"
    >
      <div className="mb-4">
        <Breadcrumb
          items={[
            { title: "Auto Click Spam" },
            { title: "Quản lý Auto Click Spam" },
          ]}
        ></Breadcrumb>
      </div>
      <div className="mb-4">
        <div className="mb-3 grid grid-cols-12 gap-x-2 gap-y-3">
          <div className="col-span-12 flex flex-wrap gap-2 sm:flex-nowrap lg:col-span-7">
            <div className="flex w-full items-center rounded-md border border-gray-300 bg-white py-1.5 sm:w-1/3">
              <input
                className="w-full bg-white pl-3"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(event.target.value)
                }
              />
              <GoSearch
                size={20}
                className="mx-3"
              />
            </div>
            <PrimaryButton
              className="w-full !py-1.5 px-5 sm:w-1/3 lg:w-max"
              content="Tìm kiếm"
              onClick={handleSearch}
              icon={
                <GoSearch
                  size={20}
                  className="mr-1"
                />
              }
            ></PrimaryButton>
          </div>
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-4">
          {selectedId.length !== 0 && userInfo?.role === "admin" && (
            <div className="my-2 flex gap-x-2">
              {/* <PrimaryButton
                className="sm:w-1/3 lg:w-max md:w-max w-max !py-1.5 px-5"
                content="Duyệt tất cả"
                icon={<BsCheck2All size={20} className="mr-1" />}
              /> */}

              <PrimaryButton
                className="w-max !py-1.5 px-5 sm:w-1/3 md:w-max lg:w-max"
                content="Xóa nhiệm vụ"
                background="bg-white border border-blue-800 !text-blue-800"
                icon={
                  <LuDelete
                    size={20}
                    className="mr-1"
                  />
                }
                onClick={handleShowModalDeleteArr}
              />
            </div>
          )}
          <div className="overflow-x-scroll bg-white">
            <Table
              columns={columns}
              dataSource={data.map((item: any, index: number) => ({
                ...item,
                key: index,
              }))}
              rowSelection={rowSelection}
              pagination={false}
            />
          </div>
          <Pagination
            className="height-pageSize mt-3"
            current={pageIndex}
            total={totalPage}
            showSizeChanger={true}
            pageSizeOptions={[10, 20, 50, 100]}
            onChange={(page, pageSize) => {
              setPageIndex(page !== 0 ? page : 1);
              setPageSize(pageSize);
            }}
          />
        </div>
      </div>
      <Modal
        title="Phê duyệt nhiệm vụ"
        okText="Phê duyệt"
        cancelText="Từ chối"
        open={isModalOpen}
        onCancel={handleCancelAccept}
        onOk={() => handleAcceptStatus(idMission, "accepted")}
      />
      <Modal
        title="Xóa nhiệm vụ"
        okText="Xóa"
        cancelText="Từ chối"
        open={isModalDeleteOpen}
        onCancel={handleModalDeleteCancel}
        onOk={() => handleDeleteMissionById(idMission)}
      />
      <Modal
        title="Xóa nhiệm vụ"
        okText="Xóa"
        cancelText="Từ chối"
        open={isModalDeleteOpen}
        onCancel={handleModalDeleteCancel}
        onOk={() => handleDeleteMissionById(idMission)}
      />
      <Modal
        title={`Xóa ${selectedId.length} nhiệm vụ được chọn`}
        okText={`Đồng ý xóa`}
        cancelText="Từ chối"
        open={isModalDeleteArrOpen}
        onCancel={handleModalDeleteArrCancel}
        onOk={() => handleDeleteMutipleMission(selectedId, selectedRecords)}
      />
      <Modal
        // title='Chạy nhiệm vụ traffic'
        okText={"Chay"}
        cancelText="Hủy"
        open={isModalStartOpen}
        footer={null}
        onCancel={() => {
          setSelectedMission(null);
          setIsModalStartOpen(false);
          setForm(initForm);
        }}
      >
        <Typography.Title level={2}>
          Chạy nhiệm vụ auto click spam
        </Typography.Title>
        <Divider />
        <Form layout="vertical">
          <Form.Item
            label="Tên nhiệm vụ"
            rules={[{ required: true }]}
          >
            <Input
              disabled
              value={selectedMission?.name}
            />
          </Form.Item>
          <Form.Item
            label="Tên miền"
            rules={[{ required: true }]}
          >
            <Input
              disabled
              value={selectedMission?.domain}
            />
          </Form.Item>
          <Form.Item
            label="Từ khóa"
            rules={[{ required: true }]}
          >
            <Input
              disabled
              value={selectedMission?.keyword}
            />
          </Form.Item>
          <Form.Item label="Proxy">
            <Radio.Group
              name="proxy"
              value={form?.proxy}
              onChange={(e) => setForm({ ...form, proxy: e.target.value })}
            >
              <Radio value="all"> Tất cả </Radio>
              <Radio value="socks5"> socks5 </Radio>
              <Radio value="tmproxy">tmproxy</Radio>
              <Radio value="proxyno1"> proxyno1 </Radio>
              <Radio value="tinsoftproxy"> tinsoftproxy </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Số luồng"
            rules={[{ required: true }]}
          >
            <Input
              onChange={(e) =>
                setForm({ ...form, thread: Number(e.target.value) })
              }
              value={form.thread}
              type="number"
            />
          </Form.Item>
          <div className="flex justify-center">
            <PrimaryButton
              onClick={handleRunTraffic}
              className="w-full !py-1.5 px-5 sm:w-1/3 lg:w-max"
              content="Chạy"
              icon={
                <FaPlay
                  size={20}
                  className="mr-1"
                />
              }
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageAutoClickSpam;
