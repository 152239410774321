import { DatePicker, Input, Select, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { RangePickerProps } from "antd/es/date-picker";
import { OptionsSelect } from "./dataFake";
import moment from "moment";
import { userAction } from "redux/user/user.slice";
import { autoClickSpamAdsAPI } from "api/auto-click-spam-ads.api";

interface IForm {
  name: string;
  domain: string;
  keyword: string;
  isFirstPage?: boolean;
  isClickLink?: boolean;
  isCopyText?: boolean;
  isClickButton?: boolean;
  isScroll?: boolean;
  status: number;
  package: string;
  totalAmount: number;
  quantity: number;
  completeTime: Array<Date>;
  price: number;
  totalTraffic: number;
}
interface IError {
  name?: any;
  url?: any;
  keyword?: any;
  completeTime?: any;
  quantity?: any;
}
const MakeProgressAutoClickSpamADS = (props:any) => {
  const {setActive} = props;
  const [api, contextHolder] = notification.useNotification();
  const [error, setError] = useState<IError>({
    name: "",
    url: "",
    keyword: "",
    completeTime: "",
    quantity: 0,
  });
  const initForm: IForm = {
    name: "",
    domain: "",
    keyword: "",
    status: 0,
    package: "1000",
    totalAmount: 0,
    quantity: 0,
    completeTime: [],
    price: 0.2,
    totalTraffic: 0,
  };
  const [form, setForm] = useState<IForm>(initForm);
  const [changeDiff, setChangeDiff] = useState<number>(0);
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch<any>();
  const handleOptionsSelect = (e: any) => {
    setForm({
      ...form,
      package: e,
      totalTraffic: Number(e)*changeDiff
    });
  };

  useEffect(() => {
    let updatedForm = { ...form };
    if (form.package === "1000") {
      updatedForm = { ...updatedForm, price: 0.2 };
    }
    setForm(updatedForm);
  }, [form.package]);

  useEffect(() => {
    const newTotalMoney =
      form && form.price && form.totalTraffic ? Math.round(form.price * Number(form.totalTraffic)) : 0;
    setForm((prevForm) => ({
      ...prevForm,
      totalAmount: newTotalMoney,
    }));
  }, [form.package, form.completeTime]);

  const onclickMakeProgress = async () => {
    let hasError = false; // Biến để theo dõi xem có lỗi hay không

    if (form.name === "") {
      setError({ ...error, name: "error" });
      api.error({
        message: "Tên nhiệm vụ không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.domain === "") {
      setError({ ...error, url: "error" });
      api.error({
        message: "Tên miền không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.keyword === "") {
      setError({ ...error, keyword: "error" });
      api.error({
        message: "Từ khóa không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (form.completeTime.length === 0 || form.completeTime === null) {
      setError({ ...error, completeTime: "error" });
      api.error({
        message: "Thời hạn hoàn thành không được bỏ trống!",
      });
      hasError = true;
      return;
    } else if (!hasError) {
      
      const dataSend = {
        completeTime: form?.completeTime,
        name: form?.name?.trim(),
        domain: form?.domain?.trim(),
        keyword: form?.keyword?.trim(),
        package: form?.package,
        totalTraffic: form?.totalTraffic,
      };
      const result: any = await autoClickSpamAdsAPI.createAutoSpamAds(dataSend);
      if (result.status === 200) {
        api.success({
          message: "Tạo nhiệm vụ thành công!",
          icon: <BsCheckCircleFill size={30} className="text-lime-600" />,
        });
        setForm({
          ...initForm,
          completeTime: form.completeTime,
          package: form?.package,
        });
        setTimeout(() => {
          setActive(1)
        }, 500);
        dispatch(
          userAction.getProfile()
        )
      }
      if (result.status === 400) {
        api.warning({
          message: `${result.message}`,
          icon: <FaMoneyBillWave size={30} className="text-lime-600" />,
        });
      }
      if (result.status === 500) {
        api.warning({
          message: `${result.message}`,
          icon: <FaMoneyBillWave size={30} className="text-lime-600" />,
        });
      }
    }
  };

  const handleDate = (
    value: any | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    if (dateString[0] === "" || dateString[1] === "") {
      setForm({
        ...form,
        completeTime: [],
      });
      setChangeDiff(0)
    } else {
      const arrDate = [value[0].toDate(), value[1].toDate()];
      const diff = value[1].diff(value[0], "day", true);
      setChangeDiff(diff)
      const totalTraffic = Number(form.package) * diff;

      setError({ ...error, completeTime: "" });
      setForm({
        ...form,
        completeTime: arrDate,
        totalTraffic,
      });
    }
  };

  return (
    <div className="mt-2">
      {contextHolder}

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">
          Nhập tên nhiệm vụ
        </div>
        <Input
          className="h-[40px] mt-1"
          placeholder="Nhập tên nhiệm vụ"
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
            setError({ ...error, name: "" });
          }}
          value={form.name}
          status={error.name}
        />
      </div>

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">Nhập tên miền</div>
        <Input
          className="h-[40px] mt-1"
          placeholder="ví dụ: https://congdongseo.com"
          onChange={(e) => {
            setForm({ ...form, domain: e.target.value });
            setError({ ...error, url: "" });
          }}
          value={form.domain}
          status={error.url}
        />
      </div>

      <div className="mt-2">
        <div className="text-lg text-green-500 font-bold">Nhập từ khóa</div>
        <Input
          className="h-[40px] mt-1"
          placeholder="Nhập từ khóa"
          onChange={(e) => {
            setForm({ ...form, keyword: e.target.value });
            setError({ ...error, keyword: "" });
          }}
          value={form.keyword}
          status={error.keyword}
        />
      </div>

      <div className="sm:block md:flex">
        <div id="google-search" className="mt-2 w-full sm:w-full md:w-1/2 py-1 pr-1">
          <div className="text-lg text-green-500 font-bold">Gói auto click spam ADS</div>
          <Select
            className="mt-1"
            style={{ width: "100%" }}
            onChange={handleOptionsSelect}
            options={OptionsSelect}
            placeholder={OptionsSelect[0].label}
          />
        </div>
        <div className="mt-2 w-full sm:w-full md:w-1/2 p-1">
          <div className="text-lg text-green-500 font-bold">Giá 1 traffic</div>
          <div className="relative">
            <Input
              className="h-[40px] mt-1"
              placeholder="Nhập url đích muốn chạy traffic"
              value={form.price}
            />
            <div className="absolute top-3.5 right-3 text-gray-500">
              credit(s)
            </div>
          </div>
        </div>
      </div>

      <div className="block md:flex lg:flex">
        <div className="mt-2 lg:w-1/2 md:w-1/2 w-full py-1 pr-1">
          <div className="text-lg text-green-500 font-bold">
            Thời hạn hoàn thành
          </div>
          <div className="w-full date-width">
            <Space direction="vertical" size={12} className="w-full">
              <RangePicker
                format={"DD-MM-YYYY"}
                disabledDate={(current) => {
                  return current && current < moment().subtract(1, "days");
                }}
                onChange={handleDate}
                status={error.completeTime}
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Space>
          </div>
        </div>
        <div className="mt-2 w-full sm:w-full md:w-1/2 p-1">
          <div className="text-lg text-green-500 font-bold">
            Tổng số traffic
          </div>
          <Input className="h-[40px] mt-1" value={new Intl.NumberFormat().format(form?.totalTraffic)} />
        </div>
      </div>

      <div className="mt-5 bg-yellow-500 rounded-lg p-4 text-center">
        <div className="text-base font-bold text-white">
          Tổng: {new Intl.NumberFormat().format(form?.totalAmount) || 0} credits
        </div>
        <div className="text-base font-normal text-white">
          Bạn sẽ mua{" "}
          <span className="font-bold">{new Intl.NumberFormat().format(form?.totalTraffic)} lượt traffic</span>{" "}
          với giá
          <span className="font-bold"> {form.price} credits</span> <b>/ traffic</b>
        </div>
      </div>
      <button
        onClick={() => onclickMakeProgress()}
        className=" mt-5 w-full text-center bg-sky-800 hover:bg-sky-700 font-bold text-base text-white p-4 rounded-lg"
      >
        Tạo nhiệm vụ
      </button>
    </div>
  );
};

export default MakeProgressAutoClickSpamADS;
