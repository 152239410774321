import { ReactElement, useState } from "react";
import { Modal, Input, Upload } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
const ConfirmModal = (props: any) => {
    const {
        title,
        buttonIcon,
        setListImage,
        setDescription,
        description,
        handleSubmit
    } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleShow = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        handleSubmit();
        setIsModalOpen(false);
    };
    const handleCancelModal = () => {
        setIsModalOpen(false);
        setFileList([])
        setListImage([])
        setDescription('')
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage((file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setListImage(newFileList)
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload (Tối đa 2 hình)</div>
        </button>
    );
    return (
        <>
            <button className={`${props.buttonStyle}`} onClick={handleShow}>
                {buttonIcon}
            </button>

            <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
                <div>
                    {title === 'Xác nhận rút tiền' ? (
                        <>
                            <b>Upload hình ảnh chuyển tiền</b>
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                maxCount={2}
                                beforeUpload={() => false}
                            >
                                {fileList.length >= 2 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal></>
                    ) : (null)}

                    <b>Ghi chú (tối đa 500 ký tự)</b>
                    <Input.TextArea  style={{ resize:'none'}} rows={10} maxLength={500} value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <div className="flex justify-end gap-3">
                    <button className="bg-primary-500 text-white rounded-md py-2 px-3" onClick={handleOk}>
                        Xác nhận
                    </button>
                    <button onClick={handleCancelModal} className="bg-white rounded-md py-2 px-3">
                        Hủy
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ConfirmModal;
