import { useEffect, useState } from "react";
import ManageMenu from "components/manageMenu";
import MakeProgressAutoClickSpam from "./MakeProgressAutoClickSpam";
import OrderdiaryAutoClickSpam from "./OrderdiaryAutoClickSpam";
import { LOCAL_STORAGE } from "helper/storage.helper";
const BuffAutoClickSpam = () => {
  const [active, setActive] = useState<number>(1);
  const [userInfo, setUserInfo] = useState<any>();
  const renderComponent = (value: number) => {
    switch (value) {
      case 1:
        return <OrderdiaryAutoClickSpam setActive = {setActive}/>;
      case 2:
        return <MakeProgressAutoClickSpam setActive = {setActive}/>;
      default:
        return null;
    }
  };
  useEffect(() => {
    const result = LOCAL_STORAGE.getCurrentUser();
    setUserInfo(result);
  }, []);
  return (
    <div className="p-4">
      <div className="text-cyan-700 font-bold text-lg">
        TẠO NHIỆM VỤ AUTO CLICK SPAM
      </div>
      <ManageMenu active={active} setActive={setActive} />

      <div>{renderComponent(active)}</div>
    </div>
  );
};

export default BuffAutoClickSpam;
