import { ReactElement, useState } from "react";
import { Modal, Input, Upload, Empty } from "antd";
import type { RcFile } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

const InfoWithdrawMoney = (props: any) => {
    const {
        openModalInfo,
        setOpenModalInfo,
        item
    } = props

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const handleCancelModal = () => {
        setOpenModalInfo(false);
    };
   

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };
    
    console.log(item?.listImage?.length)
    return (
        <>
            <Modal title={'Thông tin'} open={openModalInfo} onCancel={handleCancelModal} footer={null}>
                <div>
                    {
                        <>
                            <b>Hình ảnh chuyển tiền</b>
                            {item?.listImage?.length > 0 ? (
                                <><Upload
                                    listType="picture-card"
                                    fileList={item?.listImage}
                                    onPreview={handlePreview}
                                    disabled={true}
                                >
                                </Upload>
                                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal></>
                            ) : (<Empty />)}

                        </>
                    }

                    <b>Ghi chú</b>
                    <Input.TextArea style={{ resize:'none'}} rows={10} className={'font-bold'} value={item?.description} disabled={true} />
                </div>
                <div className="flex justify-end gap-3">
                    <button onClick={handleCancelModal} className="bg-white rounded-md py-2 px-3">
                        Đóng
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default InfoWithdrawMoney;
