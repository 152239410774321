import { Table, Tag, Tooltip } from "antd";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle, AiOutlineEye } from "react-icons/ai";
import { MdRemoveCircle } from "react-icons/md";
import PaginationCustom from "components/ui/pagination/PaginationCustom";
import SimpleSpiner from "components/ui/spiner/SimpleSpiner";
import { TypeAction, TypeGroup, WITHDRAW_STATUS } from "common/constant";
import ConfirmModal from "components/ui/modal/ModalConfirmWithdrawMoney";
import InfoWithdrawMoney from "components/ui/modal/ModalInfoWithdrawMoney";
import { sendLogs } from "common/function";
import { useState } from 'react'
import { topUpAction } from "redux/top-up/top-up.slice";
import { toast } from "react-toastify";

type PropsType = {
   pageIndex: number;
   setPageIndex: Function;
   pageSize: number;
   setPageSize: Function;
   handleSearch: Function;
};
function WithdrawTable({ pageIndex, setPageIndex, pageSize, setPageSize, handleSearch }: PropsType) {
   const [listImage, setListImage] = useState([])
   const [description, setDescription] = useState('')
   const [openModalInfo, setOpenModalInfo] = useState(false)
   const [item, setItem] = useState({})

   const handleShow = (item:any) => {
      setOpenModalInfo(true)
      setItem(item)
   }
   const columns: any = [
      {
         title: "STT",
         dataIndex: "index",
         sortDirections: ["descend", "ascend"],
      },
      {
         title: "Người rút",
         dataIndex: "user",
      },
      {
         title: "Số tiền",
         dataIndex: "money",
      },
      {
         title: "Phương thức",
         dataIndex: "method",
      },
      {
         title: "STK",
         dataIndex: "STK",
      },
      {
         title: "Ngân hàng",
         dataIndex: "bank",
      },
      {
         title: "Số điện thoại",
         dataIndex: "phone",
      },
      {
         title: "Trạng thái",
         dataIndex: "status",
      },
      {
         title: "Hình thức",
         dataIndex: "form",
      },
      {
         title: "Thời gian",
         dataIndex: "createdAt",
         width: "120px",
      },
      {
         title: "Hoạt động",
         dataIndex: "action",
      },
   ];

   const dispatch = useDispatch<any>();
   const isLoading = useSelector((state: any) => state.topUp.isLoading);

   const handleChangePageIndex = (_page = 1) => {
      setPageIndex(_page);
   };
   const handleChangePageSize = (_pageSize = 5) => {
      setPageSize(_pageSize);
   };

   const handleUpdate = ({ id, status, dataOfUser }: { id: string; status: string; dataOfUser: any }) => {
      try {
         const result = dispatch(topUpAction.updateWithdrawStatus({ id, dataOfUser, status, listImage, description }));
         result.then((data: any) => {
            if (data.error) {
               toast.error(data?.error?.message || "Cập nhật thất bại");
               return;
            }
            //
            handleSearch()
            toast.success(data.message || "Cập nhật thành công");
         });
      } catch (error) {
         toast.error("Cập nhật thất bại");
      }
   };
   const cardTopUp = useSelector((state: any) => state.topUp.withdraw);
   // console.log(cardTopUp)
   const items = cardTopUp?.items?.map((item: any, id: number) => {
      return {
         index: id + 1,
         key: id,
         user: <p className="line-clamp-2">{item?.infoUser[0]?.userName}</p>,
         money: <p className="line-clamp-2">{new Intl.NumberFormat("vi").format(item.money || 0)} VND</p>,
         method: <p className="line-clamp-2">{item.method}</p>,
         STK: <p className="line-clamp-2">{item.STK || ""}</p>,
         bank: <p className="line-clamp-2">{item.bank}</p>,
         phone: <p className="line-clamp-2">{item.phone}</p>,
         status: (
            <p className="line-clamp-2">
               {item.status === "pending" ? (
                  <Tag color="orange">Pending</Tag>
               ) : item.status === "success" ? (
                  <Tag color="green">Success</Tag>
               ) : (
                  <Tag color="red">Cancelled</Tag>
               )}
            </p>
         ),
         form: <p className="line-clamp-2">{item?.form}</p>,
         createdAt: (
            <p className="line-clamp-2">{item.createdAt ? format(new Date(item.createdAt), "dd-MM-yyyy HH:mm:ss") : "-"}</p>
         ),
         action:
            item.status === WITHDRAW_STATUS.PENDING ? (
               <div className="flex justify-center gap-2 w-full">
                  <ConfirmModal
                     title={`Xác nhận rút tiền`}
                     buttonIcon={<AiFillCheckCircle size={24} className="text-green-400" />}
                     setListImage={setListImage}
                     setDescription={setDescription}
                     description={description}
                     handleSubmit={() => {
                        handleUpdate(
                           {
                              id: item._id,
                              status: WITHDRAW_STATUS.SUCCESS,
                              dataOfUser: {
                                 nameOfBank: item.bank,
                                 money: item.money,
                                 userId: item.infoUser[0]?._id,
                                 method: item.method,
                              }
                           });
                        const options = {
                           userName: item?.infoUser[0]?.userName
                        }
                        sendLogs(
                           TypeGroup[5],
                           TypeAction[4],
                           options
                        );
                        // handleSearch();
                        setDescription('')
                     }}
                  />
                  <ConfirmModal
                     title={`Xác nhận hủy yêu cầu`}
                     buttonIcon={<MdRemoveCircle size={24} className="text-red-400" />}
                     setListImage={setListImage}
                     setDescription={setDescription}
                     description={description}
                     handleSubmit={() => {
                        handleUpdate(
                           {
                              id: item._id,
                              status: WITHDRAW_STATUS.REJECT,
                              dataOfUser: {
                                 money: item.money,
                                 userId: item.infoUser[0]?._id,
                                 method: item.method
                              }
                           });
                        const options = {
                           userName: item?.infoUser[0]?.userName
                        }
                        sendLogs(
                           TypeGroup[5],
                           TypeAction[2],
                           options
                        );
                        // handleSearch();
                        setDescription('')
                     }}
                  />
               </div>
            ) : (
               <div className="flex justify-center">
                  <Tooltip title='Xem chi tiết'>
                     <AiOutlineEye
                        size={24}
                        className=' text-sky-500 my-auto mx-auto block cursor-pointer'
                        onClick={() => handleShow(item)}
                     />
                  </Tooltip>
               </div >
            ),
      };
   });

   if (isLoading) return <SimpleSpiner isLoading={isLoading} />;

   return (
      <div className="p-4 rounded-lg border border-gray-300 bg-white">
         <div className="overflow-x-scroll bg-white">
            <Table columns={columns} dataSource={items} pagination={false} />
         </div>
         <PaginationCustom
            list={cardTopUp?.count || 0}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={(_pageSize: number) => handleChangePageSize(_pageSize)}
            setPageIndex={(_page: number) => handleChangePageIndex(_page)}
         ></PaginationCustom>
         <InfoWithdrawMoney
            openModalInfo={openModalInfo}
            setOpenModalInfo={setOpenModalInfo}
            item={item}
         />
      </div>
   );
}
export default WithdrawTable;
