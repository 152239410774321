import { Input, message, notification } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTrafficGoogle } from "api/task.api";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import ImgCrop from "antd-img-crop";
import { FiUploadCloud } from "react-icons/fi";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { userAction } from "redux/user/user.slice";
interface IForm {
  customerId?: string;
  type?: string;
  link?: string;
  follow?: number | undefined;
  price?: number | undefined;
  credit?: number | undefined;
  note?: string;
  comment?: string;
  typeTask?: number;
  image?: any;
}

const ComponentMakeProgressGoogleMapImage = (props: any) => {
  const { setActive } = props;
  const userInfo = useSelector((state: any) => state.user.currentUser);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch<any>();
  const [form, setForm] = useState<IForm>({
    customerId: userInfo?._id,
    type: "64dd9990027da93536afad50",
    link: "",
    follow: 1,
    price: 10,
    credit: 10,
    note: "",
    comment: "",
    typeTask: 2,
    image: [],
  });

  useEffect(() => {
    const newCredit =
      form && form.price && form.follow
        ? Math.round(form.price * form.follow)
        : 0;
    setForm((prevForm) => ({
      ...prevForm,
      credit: newCredit,
    }));
  }, [form.price, form.follow]);

  const onclickMakeProgress = async () => {
    if (form.link === "")
      return message.warning("Link google map không được bỏ trống!");
    if (form.comment === "")
      return message.warning("Nội dung đánh giá không được bỏ trống!");
    // if (form.image.length === 0)
    //   return message.warning("Hình ảnh google map không được bỏ trống!");
    const formData = new FormData();
    const formJsonString = JSON.stringify(form);
    formData.append("data", formJsonString);

    fileList?.forEach((fileModal: any) => {
      formData.append("file", fileModal.originFileObj);
    });
    const result: any = await createTrafficGoogle(formData);
    if (result.statusCode === 1) {
      setFileList([]);
      setForm({
        ...form,
        link: "",
        comment: "",
        image: [],
      });
      api.success({
        message: "Tạo tiến trình thành công!",
        icon: (
          <BsCheckCircleFill
            size={30}
            className="text-lime-600"
          />
        ),
      });
      dispatch(userAction.getProfile());
      setTimeout(() => {
        setActive(1);
      }, 500);
    }
    if (result.statusCode === 0) {
      api.warning({
        message: `${result.message}`,
        icon: (
          <FaMoneyBillWave
            size={30}
            className="text-lime-600"
          />
        ),
      });
    }
  };

  const hanldeOnBlur = () => {
    if (Number(form.price) < 10) {
      setForm({ ...form, price: 10 });
      api.warning({
        message: "giá credit ít nhẫn mỗi lần là 10 credits",
      });
    }
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    // setForm({ ...form, image: newFileList });
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="mt-2 flex flex-row">
      {contextHolder}
      <div className="basis-4/6">
        <div className="flex ">
          <div className="w-1/4 text-base font-bold text-cyan-700">
            Nhập link map:
          </div>
          <div className="w-3/4">
            <Input
              className="h-[40px]"
              placeholder="https://www.google.com/maps/place/Khu+c%C3%B4ng+nghi%E1%BB%87p+v%C4%A9nh+l%E1%BB%99c/@10.8068336,106.6331024,14z/data=!4m8!3m7!1s0x31752be712db2633:0xf4b167a753fa7bf3!8m2!3d10.825627!4d106.5980572!9m1!1b1!16s%2Fg%2F11f65hs25m?entry=ttu"
              onChange={(e) => {
                setForm({ ...form, link: e.target.value });
              }}
              value={form.link}
            />
          </div>
        </div>

        <div className="mt-5 flex">
          <div className="w-1/4 text-base font-bold text-cyan-700">
            Giá credit mỗi tương tác:
            <div className="mt-1 text-base font-bold text-red-400">
              Giá thấp nhất: 10 credits
            </div>
          </div>
          <div className="relative w-3/4">
            <Input
              className="h-[40px]"
              value={form.price}
              type="number"
              onChange={(e) => {
                setForm({ ...form, price: Number(e.target.value) });
              }}
              step={0.5}
              min={10}
              onBlur={hanldeOnBlur}
            />
            <div className="absolute right-6 top-2.5 select-none text-gray-500">
              credit(s)
            </div>
            <div className="mt-3 rounded-lg bg-white p-3">
              <div className="text-base font-medium text-cyan-700">
                Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn
                cần gấp bạn có thể set giá job của mình cao hơn 1 vài đồng để
                chạy nhanh nhất có thể nhé.
              </div>
              <div className="mt-1 text-base font-bold text-red-400">
                Lưu ý: Nên buff dư thêm 30 - 50% trên tổng số lượng để tránh
                tụt.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 flex">
          <div className="w-1/4 text-base font-bold text-cyan-700">
            Nội dung đánh giá:
          </div>
          <div className="w-3/4">
            <Input.TextArea
              rows={5}
              placeholder="Nhập nội dung đánh giá"
              onChange={(e) => {
                setForm({ ...form, comment: e.target.value });
              }}
              value={form.comment}
            />
          </div>
          sf
        </div>
        <div className="mt-3 flex">
          <div className="w-1/4 text-lg font-bold text-cyan-700">
            Thêm hình ảnh mô tả
          </div>
          <div className="flex w-3/4 items-center justify-end">
            <ImgCrop rotationSlider>
              <Upload
                className=""
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                <FiUploadCloud
                  className="mr-1"
                  size={40}
                />
              </Upload>
            </ImgCrop>
          </div>
        </div>
        <div className="mt-5 flex">
          <div className="w-1/4 text-base font-bold text-cyan-700">
            Ghi chú:
          </div>
          <div className="w-3/4">
            <Input.TextArea
              className="h-[80px]"
              placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
              onChange={(e) => {
                setForm({ ...form, note: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="mt-5 rounded-lg bg-yellow-500 p-4 text-center">
          <div className="text-base font-bold text-white">
            Tổng: {Math.round(form?.credit || 5)} credits
          </div>
          <div className="text-base font-normal text-white">
            Bạn sẽ buff <span className="font-bold">{form.follow} review</span>{" "}
            với giá
            <span className="font-bold"> {form.price}</span> credits/image
          </div>
        </div>
        <button
          onClick={() => onclickMakeProgress()}
          className=" mt-5 w-full rounded-lg bg-sky-800 p-4 text-center text-base font-bold text-white hover:bg-sky-700"
        >
          Tạo tiến trình
        </button>
      </div>
      <div className="basis-2/6 pl-5 ">
        <div className="rounded-lg bg-orange-300 p-4">
          <div className="text-base font-semibold text-slate-900 ">Chú ý:</div>
          <div className="mt-4 text-base font-semibold text-slate-900">
            - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính
            trị, đồ trụy... Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi
            hệ thống vĩnh viễn, và phải chịu hoàn toàn trách nhiệm trước pháp
            luật.
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Hệ thống sử dụng tài khoản người VN
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Mỗi id có thể Buff tối đa 10 lần trên hệ thống để tránh Spam, max
            50k, số lượng càng lớn thì thời gian chạy càng lâu
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Thời gian tăng subscribe tính từ ngày bắt đầu cho đến ngày kết
            thúc job, tối đa là 1 tuần
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Hết thời gian của job đã order nếu không đủ số lượng sẽ tự động
            hoàn lại số tiền seeding chưa tăng cho bạn trong vòng từ 2 đến 4
            ngày
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Vui lòng lấy đúng link trang cá nhân và không nhập id khác
          </div>
          <div className="mt-4 text-base font-normal text-slate-900">
            - Check kỹ job tránh tạo nhầm, tính năng đang trong giai đoạn thử
            nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
          </div>
          <div className="mt-4 text-base font-semibold text-slate-900">
            - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không
            hoàn lại tiền.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentMakeProgressGoogleMapImage;
