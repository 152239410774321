import { Table, Popover, Tag } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { BsCheck2All } from "react-icons/bs";
import { useState } from "react";
import PaginationCustom from "components/ui/pagination/PaginationCustom";
import ConfirmModal from "components/ui/modal/ConfirmModal";
import SimpleSpiner from "components/ui/spiner/SimpleSpiner";
import { Action, Group, TypeAction, TypeGroup } from "common/constant";

type PropsType = {
  pageIndex: number;
  setPageIndex: Function;
  pageSize: number;
  setPageSize: Function;
  handleSearch: Function;
  setSelectedId: any;
};
function ListLogsTable({
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  handleSearch,
  setSelectedId,
}: PropsType) {
  const columns: any = [
    {
      title: "STT",
      dataIndex: "index",
      width: 60,
      align: "center",
      sorter: (a: any, b: any) => a.index - b.index,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Người tạo",
      dataIndex: "userName",
      width: 120,
      align: "center",
      ellipsis: true,
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      width: 280,
      align: "center",
      render: (value: any) => {
        return (
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: value.props.children }}
          ></div>
        );
      },
    },
    {
      title: "Group",
      dataIndex: "typeGroup",
      width: 120,
      align: "center",
      ellipsis: true,
      render: (value: any) => Group[value.props.children],
    },
    {
      title: "Hành động",
      dataIndex: "typeAction",
      width: 120,
      align: "center",
      // render: (value: any) => Action[value.props.children],
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      width: 100,
      align: "center",
      render: (value: any) =>
        moment(value.props.children).format("HH:mm DD/MM/YYYY"),
    },
  ];

  const dispatch = useDispatch<any>();
  const newsData = useSelector((state: any) => state.logs.listLogs) || [];
  const countData = useSelector((state: any) => state.logs.count) || 0;
  const isLoading = useSelector((state: any) => state.logs.isLoading);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();

  const handleChangePageIndex = (_page = 1) => {
    setPageIndex(_page);
  };
  const handleChangePageSize = (_pageSize = 5) => {
    setPageSize(_pageSize);
  };
  const listLogs = newsData?.map((item: any, id: number) => {
    return {
      index: id + 1,
      key: id,
      _id: item?._id,
      userName: <p className="line-clamp-2">{item.userName}</p>,
      content: <p className="line-clamp-2">{item.content}</p>,
      typeGroup: <p className="line-clamp-2">{item.typeGroup}</p>,
      typeAction: (
        <p className="line-clamp-2">
          <Tag color="blue">{Action[item.typeAction]}</Tag>
        </p>
      ),
      createDate: <p className="line-clamp-2">{item.createdDate}</p>,
    };
  });

  if (isLoading) return <SimpleSpiner isLoading={isLoading} />;

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    const arrId: Array<object> = [];
    // const credit = 1;
    selectedRows.map((row: any) => {
      // const obj = {
      //   _id: row?._id,
      //   userId: row?.userId,
      //   credit,
      // };
      // arrId.push(obj);
    });
    setSelectedId([...arrId]);
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <div className="rounded-lg border border-gray-300 bg-white p-4">
      <div className="overflow-x-scroll bg-white">
        <Table
          columns={columns}
          dataSource={listLogs}
          pagination={false}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="font-medium">Tổng: {countData} bản ghi</div>
        <PaginationCustom
          list={countData || 0}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={(_pageSize: number) => handleChangePageSize(_pageSize)}
          setPageIndex={(_page: number) => handleChangePageIndex(_page)}
        ></PaginationCustom>
      </div>
    </div>
  );
}
export default ListLogsTable;
