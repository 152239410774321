import { Breadcrumb, Select } from "antd";
import { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { useDispatch } from "react-redux";

import { AccountStatus, ROLES } from "common/constant";
import PrimaryButton from "components/ui/button/PrimaryButtton";
import { userAction } from "redux/user/user.slice";
import MembersTable from "./components/MembersTable";

function AdminMembers() {
  const dispatch = useDispatch<any>();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);

  const handleSearch = () => {
    dispatch(
      userAction.getListAdmins({
        page: pageIndex,
        limit: pageSize,
        search,
        status: statusFilter,
        role: [ROLES.USER, ROLES.SALES],
      }),
    );
  };
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    dispatch(
      userAction.getListAdmins({
        page: pageIndex,
        limit: pageSize,
        role: [ROLES.USER, ROLES.SALES],
      }),
    );
  }, [pageIndex, pageSize]);

  return (
    <div className="p-4">
      <div className="mb-4">
        <Breadcrumb
          items={[
            { title: "Quản lý thành viên" },
            { title: "Admin cộng đồng SEO" },
          ]}
        ></Breadcrumb>
      </div>
      <div className="mb-4">
        <h3 className="mb-4 font-semibold">Admin cộng đồng SEO</h3>

        <div className="mb-3 flex flex-wrap justify-between gap-2 sm:flex-nowrap">
          <div className="flex w-full flex-wrap gap-2 sm:basis-2/3 sm:flex-nowrap">
            <div className="flex w-full items-center rounded-md border border-gray-300 bg-white py-1.5">
              <input
                className="w-full bg-white pl-3"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                onKeyDown={handleEnter}
              ></input>
              <GoSearch
                size={20}
                className="mx-3"
              />
            </div>
            <Select
              id="select-status"
              allowClear
              placeholder="Trạng thái"
              className="w-full [&>div]:!h-9 [&>div]:!py-1"
              options={AccountStatus}
              onChange={(values) => {
                setStatusFilter(values);
              }}
            />
            <PrimaryButton
              className="w-full !py-1.5 px-5 sm:w-max"
              content="Tìm kiếm"
              onClick={handleSearch}
            ></PrimaryButton>
          </div>

          {/* <div className="w-full sm:w-max">
                        <Link to="/quan-ly-thanh-vien/them-thanh-vien">
                            <PrimaryButton
                                className="w-full !py-1.5 px-5"
                                content="Thêm mới"
                                icon={
                                    <IoAddCircleOutline
                                        size={20}
                                        className="mr-1"
                                    />
                                }
                            ></PrimaryButton>
                        </Link>
                    </div> */}
        </div>
      </div>
      <MembersTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handleSearch={handleSearch}
        memberType={1} // 0: condongseo account  1: admin congdongseo
        admin={true}
      />
    </div>
  );
}
export default AdminMembers;
