import { Editor } from "@tinymce/tinymce-react";
import { Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib/upload";
import { useRef, useState } from "react";
import { BiNews } from "react-icons/bi";
import { FiUploadCloud } from "react-icons/fi";
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createNewPost,
  getPagingPost,
} from "redux/postCommunity/postCommunity.slice";

const NewPost = () => {
  const dispatch = useDispatch<any>();
  const userInfo = useSelector((state: any) => state?.user?.currentUser);
  const [contentString, setContentString] = useState<string>("");

  const [length, setLength] = useState(0);
  const [content, setContent] = useState<string>("");

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [post, setPost] = useState<any>(null); //! aka form data

  const editorRef = useRef<any>(null);

  const handleShowEditor = () => {
    setShowEditor((prev) => !prev);
  };
  const handleEditorChange = (content: any, editor: any) => {
    const contentSlug = editor.getContent({ format: "text" });
    setLength(length);
    setContent(content);
    setContentString(
      contentSlug.length >= 160
        ? `${contentSlug.substring(0, 160)}...`
        : contentSlug,
    );
  };

  const handleNewPost = async () => {
    const formData = new FormData();
    formData.append("content", content);
    if (fileList.length > 0) {
      //@ts-ignore
      formData.append("image", fileList[0].originFileObj);
    }
    if (content === "" && fileList.length === 0) {
      toast("Bạn vui lòng nhập nội dung bài viết");
      return;
    }
    dispatch(createNewPost(formData));
    setFileList([]);
    setContent("");
    editorRef.current.setContent("");
    setShowEditor(false);
  };

  const onHandleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    setPost({ ...post, image: newFileList });
  };

  const onHandlePreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className={`new-post myHomeContainer space-y-4`}>
      <div className="flex flex-col items-start gap-3 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex items-center gap-x-3">
          <div className="h-10 w-10 overflow-hidden rounded-full">
            {userInfo?.avatar ? (
              <img
                src={userInfo?.avatar}
                className="aspect-square w-full object-cover
              "
                alt="avatar"
              />
            ) : (
              
                <RxAvatar size={40}/>
              
            )}
          </div>
          <span className="font-light text-gray-500">Bạn đang nghĩ gì?</span>
        </div>
        <button
          className="flex w-full items-center
          gap-x-2 rounded-md bg-blue-400 px-3 py-2 text-white lg:w-fit"
          onClick={() => handleShowEditor()}
        >
          <BiNews
            size={24}
            className=""
          />
          <span className="font-semibold">Chia sẻ ngay</span>
        </button>
      </div>
      <div
        className={`editor ${
          showEditor ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        } grid transition-all duration-500 ease-out`}
      >
        <div className=" space-y-4 overflow-hidden">
          <Editor
            onEditorChange={handleEditorChange}
            onInit={(evt, editor: any) => {
              editorRef.current = editor;
            }}
            apiKey='i90dbv242azw8c5um2zf4pydfpl3qd9xrqubimrguvszeaxq'
            init={{
              height: 300,
              menubar: false,
              plugins: ["wordcount", "link"],
              toolbar:
                "undo redo | fontsize |" +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help | link",

              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              toolbar_mode: "wrap",
            }}
          />
          <div className="">
            <p>Thêm hình ảnh </p>
            <div>
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onHandleChange}
                  onPreview={onHandlePreview}
                >
                  {fileList.length < 1 && (
                    <div className="flex flex-col items-center justify-center">
                      <FiUploadCloud size={40} />
                      <p className="text-sm">Tải lên</p>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </div>
          </div>
          <button
            className="ml-auto mr-0 flex
          items-center gap-x-2 rounded-md bg-blue-400 px-3 py-2 text-white"
            onClick={() => handleNewPost()}
          >
            Thêm bài viết mới
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPost;
