import { Table } from "antd";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import PaginationCustom from "components/ui/pagination/PaginationCustom";
import SimpleSpiner from "components/ui/spiner/SimpleSpiner";

type PropsType = {
   pageIndex: number;
   setPageIndex: Function;
   pageSize: number;
   setPageSize: Function;
};
function TransferTopUpTable({ pageIndex, setPageIndex, pageSize, setPageSize }: PropsType) {
   const columns: any = [
      {
         title: "STT",
         dataIndex: "index",
         width: 52
      },
      {
         title: "Thời gian tạo",
         dataIndex: "createDate",
         align: "center",
         width: 160,
         ellipsis: true
      },
      {
         title: "Credit/ tiền",
         dataIndex: "credit",
         align: "center",
         width: 170,
         ellipsis: true
      },
      {
         title: "Nội dung chuyển khoản",
         dataIndex: "paymentContent",
         width: 200
      },
      {
         title: "Thời gian chuyển",
         dataIndex: "paymentDate",
         align: "center",
         width: 170
      },
      {
         title: "Trạng thái",
         dataIndex: "status",
         width: "230px",
         align: "center",
         render: (value:any) => {
            if (value === "đang kiểm tra") {
              return (
                <div className="text-center">
                  <span className="bg-red-500 text-white rounded-lg text-center py-1 px-2 whitespace-nowrap">
                    {value}
                  </span>
                </div>
              );
            }
            if (value === "Đã thanh toán, chưa cộng credit") {
              return (
                <div className="text-center">
                  <span className="bg-orange-500 text-white rounded-lg text-center py-1 px-2 whitespace-nowrap">
                    {value}
                  </span>
                </div>
              );
            }
            if (value === "Đã thanh toán, đã cộng credit") {
               return (
                 <div className="text-center">
                   <span className="bg-green-500 text-white rounded-lg text-center py-1 px-2 whitespace-nowrap">
                     {value}
                   </span>
                 </div>
               );
             }
        }
      },
   ];
   const isLoading = useSelector((state: any) => state.topUp.isLoading);

   const handleChangePageIndex = (_page = 1) => {
      setPageIndex(_page);
   };
   const handleChangePageSize = (_pageSize = 5) => {
      setPageSize(_pageSize);
   };

   const transferTopUp = useSelector((state: any) => state.topUp.transferTopUp) || [];

   const items = transferTopUp?.items?.map((item: any, id: number) => {
      const paymentStatus: any = {
         1: "đang kiểm tra",
         2: "Đã thanh toán, chưa cộng credit",
         3: "Đã thanh toán, đã cộng credit"
     };
      return {
         index: id + 1,
         key: id,
         createDate: <p className="line-clamp-2">{item.createDate ? format(new Date(item.createDate), "dd-MM-yyyy hh:mm") : "-"}</p>,
         credit: <p className="line-clamp-1">{`${item.creditWanted}/ ${new Intl.NumberFormat("vi").format(item.paymentNeed || 0)} VND`}</p>,
         paymentContent: <p className="line-clamp-2">{item.paymentContent ? item.paymentContent : "-"}</p>,
         paymentDate: (
            <p className="line-clamp-2">
               {item.paymentDate ? format(new Date(item.paymentDate), "dd-MM-yyyy hh:mm") : "-"}
            </p>
         ),
         status: paymentStatus[item?.status],
      };
   });

   if (isLoading) return <SimpleSpiner isLoading={isLoading} />;

   return (
      <div className="p-4 rounded-lg border border-gray-300 bg-white">
         <div className="overflow-x-auto bg-white ">
            <Table columns={columns} dataSource={items} pagination={false}/>
         </div>
         <PaginationCustom
            list={transferTopUp?.count || 0}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={(_pageSize: number) => handleChangePageSize(_pageSize)}
            setPageIndex={(_page: number) => handleChangePageIndex(_page)}
         ></PaginationCustom>
      </div>
   );
}
export default TransferTopUpTable;
