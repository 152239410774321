const Notification = () => {
  return (
    <div className={`noti myHomeContainer space-y-3`}>
      <div className="flex items-center justify-between">
        <p>Thông báo</p>
        <button className="rounded bg-blue-400 px-2 py-1 font-bold text-white">
          Mới nhất
        </button>
      </div>
      <hr />
    </div>
  );
};
export default Notification;
