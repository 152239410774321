import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postCommunityAPI } from "api/post-community.api";

export const getPagingPost = createAsyncThunk(
  "postCommunity/getData",
  async (params: any) => {
    const pageSize = params?.pageSize || 10;
    const pageIndex = params?.pageIndex || 1;

    const res = await postCommunityAPI.getPagingPost(pageSize, pageIndex);
    return res.data;
  },
);
export const loadMore = createAsyncThunk(
  "postCommunity/loadMore",
  async (params: any) => {
    const pageSize = params?.pageSize || 10;
    const pageIndex = params?.pageIndex || 1;

    const res = await postCommunityAPI.getPagingPost(pageSize, pageIndex);
    return res.data;
  },
);

export const createNewPost = createAsyncThunk(
  "postCommunity/createNewPost",
  async (data: any, { dispatch }) => {
    const res = await postCommunityAPI.createNewPost(data);
    if (res.status === 200) {
      dispatch(getPagingPost({ pageSize: 1000, pageIndex: 1 }));
    }
    return res.data;
  },
);

export const deletePost = createAsyncThunk(
  "postCommunity/deletePost",
  async (postId: any, { dispatch }) => {
    const res = await postCommunityAPI.deletePost(postId);
    if (res.status === 200) {
      dispatch(getPagingPost({ pageSize: 10, pageIndex: 1 }));
    }
    return res.status;
  },
);

export const getCommentByPost = createAsyncThunk(
  "postCommunity/getCommentByPost",
  async (postId: string) => {
    const res = await postCommunityAPI.getCommentByPost(postId);
    return res.data;
  },
);

export const createComment = createAsyncThunk(
  "postCommunity/createComment",
  async ({ content, postId }: any, { dispatch }) => {
    const res = await postCommunityAPI.createComment({ content, postId });
    if (res.status === 200) {
      dispatch(increaseComment(postId));
    }
    return res.data;
  },
);

export const likePost = createAsyncThunk(
  "postCommunity/likePost",
  async (postId: any, { dispatch }) => {
    const res = await postCommunityAPI.likePost(postId);
    if (res.status === 200) {
      dispatch(reaction(res.data));
    }
    return res.data;
  },
);

export const unlikePost = createAsyncThunk(
  "postCommunity/unlikePost",
  async (postId: any, { dispatch }) => {
    const res = await postCommunityAPI.unlikePost(postId);
    if (res.status === 200) {
      dispatch(reaction(res.data));
    }
    return res.data;
  },
);

export const deleteComment = createAsyncThunk(
  "postCommunity/deleteComment",
  async ({ cmtId, postId }: any, { dispatch }) => {
    const res = await postCommunityAPI.deleteComment(cmtId);
    if (res.status === 200) {
      dispatch(getCommentByPost(postId));
      dispatch(decreaseComment(postId));
    }
    return res.data;
  },
);

export const updateComment = createAsyncThunk(
  "postCommunity/updateComment",
  async ({ cmtId, content, postId }: any, { dispatch }) => {
    const res = await postCommunityAPI.updateComment(cmtId, content);
    if (res.status === 200) {
      dispatch(getCommentByPost(postId));
    }
    return res.data;
  },
);

const postCommunitySlice = createSlice({
  name: "postCommunity",
  initialState: {
    posts: [] as any[],
    loading: false,
    totalDoc: 0,
  },
  reducers: {
    reaction: (state, action) => {
      state.posts = [
        ...state.posts.map((post) => {
          if (post._id === action.payload._id) {
            return { ...action.payload, totalComment: post.totalComment };
          } else {
            return post;
          }
        }),
      ];
    },

    increaseComment(state, action) {
      state.posts = [
        ...state.posts.map((post) => {
          if (post._id === action.payload) {
            return { ...post, totalComment: (post?.totalComment || 0) + 1 };
          } else {
            return post;
          }
        }),
      ];
    },
    decreaseComment(state, action) {
      state.posts = [
        ...state.posts.map((post) => {
          if (post._id === action.payload) {
            return { ...post, totalComment: (post?.totalComment || 0) - 1 };
          } else {
            return post;
          }
        }),
      ];
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getPagingPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPagingPost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload.data;
        state.totalDoc = action.payload.totalDoc;
      })
      .addCase(getPagingPost.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loadMore.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadMore.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = [...state.posts, ...action.payload.data];
        state.totalDoc = action.payload.totalDoc;
      })
      .addCase(loadMore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(likePost.fulfilled, (state, action) => {
        state.posts.map((post: any) =>
          post._id === action.payload._id ? action.payload : post,
        );
      })
      .addCase(unlikePost.fulfilled, (state, action) => {
        state.posts.map((post: any) =>
          post._id === action.payload._id ? action.payload : post,
        );
      });
  },
});
export const { reaction, increaseComment, decreaseComment } =
  postCommunitySlice.actions;
export default postCommunitySlice.reducer;
