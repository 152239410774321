import { Modal, message } from "antd";
import { paymentManagerAPI } from "api/payment-manager.api";
import SecondaryButton from "components/ui/button/SecondaryButton";
import { useState, useEffect, useRef } from "react";
import { AiFillCheckCircle, AiOutlineCopy } from "react-icons/ai";
import { useSelector } from "react-redux";

function LoadByTransfer() {
   const userInfo = useSelector((state: any) => state.user.currentUser);
   const bodyTransfer = `${userInfo?.userName}`;
   const [isClicked, setIsClicked] = useState(false);
   const [inputPrice, setInputPrice] = useState<string>("20,000");
   const [activePrice, setActivePrice] = useState<number>(0);
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
   const [isModalConfirmOpen, setIsModalConfirmOpen] = useState<boolean>(false);
   const [getCredit, setGetCredit] = useState<number>(20);
   const [paymentPrice, setPaymentPrice] = useState<number>(20000);
   const refInput:any = useRef()

   const BANK_INFOS = [
      {
         "Ngân hàng": "ACB",
         "Chủ TK": "Nguyễn Trung Hiếu",
         "STK": "5318731",
         "Mã QR": <img 
            src={`https://img.vietqr.io/image/ACB-5318731-compact2.png?amount=${paymentPrice}&amp&addInfo=${userInfo?.userName} ${getCredit}credit&accountName=NGUYEN%20TRUNG%20HIEU`}
            className="w-[250px] rounded-lg"
            ></img>
      }
   ];

   const releaseCredit = () => {
      const cutString = Number(inputPrice.replace(/,/g, ''));
      const credit = Math.floor(cutString*1/1000);
      return credit
   }

   const calculateCredit = () => {
      return `${new Intl.NumberFormat('en').format(releaseCredit())} credit`;
      
    }

    useEffect (() => {
      setGetCredit(releaseCredit());
   },[inputPrice])

   useEffect (() => {
      refInput.current.focus();
   },[])

    const showCredit = () => {
      switch(inputPrice) {
          case "20,000": {
              return "20 credit"
          };
          case "50,000": {
              return "50 credit"
          };
          case "100,000": {
              return "100 credit"
          };
          case "200,000": {
              return "200 credit"
          };
          case "500,000": {
            return "500 credit"
          };
          case "1,000,000": {
            return "1,000 credit"
          };
          default : return calculateCredit()
        }
    }

    const priceLabel = [
      {
          price: 20000,
          label: "20,000",
          id: 0,
          credit: 20
      },
      {
          price: 50000,
          label: "50,000",
          id: 1,
          credit: 50
      },
      {
          price: 100000,
          label: "100,000",
          id: 2,
          credit: 100
      },
      {
          price: 200000,
          label: "200,000",
          id: 3,
          credit: 200
      },
      {
         price: 500000,
         label: "500,000",
         id: 4,
         credit: 500
     },
     {
         price: 1000000,
         label: "1,000,000",
         id: 5,
         credit: 1000000
     },
    ];

    const handClickchoosePrice = (lable: string, id:number, credit:number, price:number) => {
      setInputPrice(lable)
      setActivePrice(id)
      setGetCredit(credit)
      setPaymentPrice(price)
   }
   const handleChoosePrice = (e:any) => {
      if (e.target.value.match(/[a-z|A-Z]/) !== null) e.target.value = 0;
      // Remove any existing commas from the input
      const sanitizedValue = e.target.value.replace(/,/g, '');
      // Format the number with commas
      const formattedValue = Number(sanitizedValue).toLocaleString();
      setInputPrice(formattedValue)
      setPaymentPrice(sanitizedValue)
   }

   const handleAccept = async() => {
      if (paymentPrice < 20000) {
         message.warning("Số tiền nạp ít nhất là 20,000đ");
         return
      }
      setIsModalConfirmOpen(true);
    }

   const handleOk = () => {
      setIsModalOpen(false);
   }

   const handleConfirmOk = async () => {
      setIsModalConfirmOpen(false);
      try {
         const formData = new FormData();
         // formData.append("file", imageInvoice);
         const price = inputPrice.split(",").join("");
         const obj = {
            userIdBuy: userInfo?.userIdCDS,
            creditWanted: getCredit,
            paymentNeed: price,
            userNameBuy: userInfo?.userName,
            paymentContent: `${bodyTransfer} ${getCredit}credit`
         };
         formData.append("body", JSON.stringify(obj));
         const result:any = await paymentManagerAPI.userBuyCredit(formData);
         if (result?.uniqueCode === 1) {
            setIsModalOpen(true);
         }
      } catch (error) {
         console.log('error', error)
      }
   }

   const handleConfirmCancel = () => {
      setIsModalConfirmOpen(false);
   }

   return (
      <div className="py-4">
         <h5 className="text-primary-500">Quy đổi trong hệ thống: 1 credit = 1.000 VNĐ</h5>
         <br></br>
         <p className="text-primary-500">
            Bạn vui lòng chuyển khoản chính xác nội dung chuyển khoản bên dưới hệ thống sẽ tự động cộng credit cho bạn sau khi hệ thống nhận được tiền. Nếu chuyển khác ngân hàng sẽ mất thời gian lâu hơn, tùy thời gian xử lý của
            mỗi ngân hàng Nếu sau 30 phút từ khi tiền trong tài khoản của bạn bị trừ mà bạn vẫn chưa được cộng tiền vui
            lòng liên hệ số điện thoại bên cạnh để được hỗ trợ.
         </p>
         <br />
         <p className="text-primary-500">
            Bạn cần nhấn vào <b>nút xác nhận</b> để hoàn thành đơn hàng.
         </p>
         <br></br>

         <div className="lg:flex md:block block">
            <div className="text-base lg:w-[25%] md:w-full w-full my-auto">
               Số lượng credit cần mua:
            </div>
            <div className="bg-sky-200 rounded-lg shadow-sm shadow-gray-500 lg:p-4 md:p-4 p-0 lg:w-[75%] md:w-full w-full lg:mt-0 md:mt-3 mt-3">
               <div className="lg:mt-[48px] md:mt-[48px] mt-[2px] lg:w-[656px] md:min-w-[450px] w-[196px] mx-auto">
                  <div className="text-center relative lg:w-full md:w-full w-full">
                     <input 
                           type="text" 
                           className="lg:text-7xl md:text-7xl text-5xl font-semibold inline-block bg-sky-200
                           lg:min-w-[250px] md:min-w-[250px] min-w-[196px] lg:max-w-[450px] md:max-w-[450px] max-w-[196px] border-b-2 text-center"
                           value={inputPrice}
                           onChange={handleChoosePrice}
                           ref = {refInput}
                     />
                     <div className="font-semibold inline-block absolute top-3 text-3xl">đ</div>
                  </div>
                  <div className="text-center font-bold">
                     {showCredit()}
                  </div>
               </div>
               <div className="lg:mt-[48px] md:mt-[48px] mt-10 w-full lg:px-[10%] md:px-[2%] px-[10%] mx-auto flex">
                  <div className="items-center text-center mx-auto flex flex-wrap w-full gap-2 mb-2">
                    {
                        priceLabel.map((el:any, index:number) => {
                            return (
                              <div className="mx-auto lg:w-1/4 md:w-1/4 w-full" key={index}>
                                <div
                                    className=
                                    {
                                        activePrice === index ?
                                        "border lg:min-w-[98px] md:min-w-[98px] min-w-[88px] text-center py-3 rounded-full lg:mt-0 mt-2 cursor-pointer bg-black-200 text-white":
                                        "border lg:min-w-[98px] md:min-w-[98px] min-w-[88px] text-center py-3 rounded-full lg:mt-0 mt-2 cursor-pointer hover:bg-gray-300 hover:text-white bg-white"
                                    }
                                    onClick={() => {handClickchoosePrice(el?.label, el?.id, el?.credit, el?.price)}}
                                >
                                    {el?.label}
                                </div>
                              </div>
                            )
                        })
                    }
                  </div> 
               </div>
            </div>
         </div>

         {BANK_INFOS.map((item: any, index: number) => (
            <div className="grid grid-cols-12 my-4" key={index}>
               <label className="md:col-span-12 col-span-12 lg:col-span-3 text-base my-auto">Tài khoản ngân hàng:</label>
               <div className="md:col-span-12 col-span-12 lg:col-span-9 bg-sky-200 rounded-lg shadow-sm shadow-gray-500 w-full p-4 lg:mt-0 md:mt-3 mt-3">
                  <table className="w-full">
                     <tbody>
                        {Object.entries(item).map((subItem: any, id: number) => (
                           <tr key={id}>
                              <td>{subItem[0]}:</td>
                              <td className="font-semibold text-primary-500">{subItem[1]}</td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
         ))}

         <div className="grid grid-cols-12">
            <label className="md:col-span-12 col-span-12 lg:col-span-3 text-base my-auto">Nội dung chuyển khoản:</label>
            <div 
               className="md:col-span-12 col-span-12 lg:col-span-9 lg:flex md:flex block justify-between items-center 
               bg-amber-200 rounded-lg shadow-sm shadow-gray-500 w-full p-4 lg:mt-0 md:mt-3 mt-3"
            >
               <h4 className="font-semibold text-gray-700">{`${bodyTransfer} ${getCredit}credit`}</h4>
               <SecondaryButton
                  background="bg-white hover:bg-gray-100"
                  content={isClicked ? "Copied!" : "Copy"}
                  icon={
                     isClicked ? (
                        <AiFillCheckCircle className="text-green-400 me-2" />
                     ) : (
                        <AiOutlineCopy className="me-2" />
                     )
                  }
                  onClick={() => {
                     if (!isClicked) {
                        setIsClicked(true);
                        const clipboardInterval = setInterval(() => {
                           setIsClicked(false);
                           clearInterval(clipboardInterval);
                        }, 3000);
                     }

                     navigator.clipboard.writeText(`${bodyTransfer} ${getCredit}credit`);
                  }}
               />
            </div>
         </div>
         <button
                  className="bg-primary-500 text-white rounded-md py-2 px-3 ml-auto block mt-5 hover:opacity-50"
                  type="button"
                  onClick={() => {
                     setIsModalOpen(false);
                     handleAccept();
                  }}
               >
                  Xác nhận
         </button>
         <Modal
            open={isModalOpen}
            footer = {false}
            onCancel={() => setIsModalOpen(false)}
         >
            <h1 className="text-center mt-6 font-semibold">Mua thành công🎉</h1>
            <div className="text-center mt-8">
                    Bạn đã mua thành công <span className="text-[#58bd7d]">{getCredit} credit</span> từ Congdongseo
            </div>
            <button 
               className="text-center mt-8 border lg:w-[384px] md:w-[384px] w-[315px] py-4 px-6 text-white
               rounded-full mx-auto block text-base bg-gradient-to-r from-primary-500 to-primary-900"
               onClick={handleOk}
            >
                Xác nhận
            </button>
         </Modal>
         <Modal
            open={isModalConfirmOpen}
            onOk={handleConfirmOk}
            title={`Xác nhận mua credit`}
            okText="Có"
            onCancel={handleConfirmCancel}
            cancelText="Không"
        >
            <p>{`Bạn muốn mua: ${getCredit} credit ?`}</p>
        </Modal>
      </div>
   );
}
export default LoadByTransfer;
