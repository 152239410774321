import React, { useRef, useState } from "react";
import { BiNews } from "react-icons/bi";
import { RxAvatar, RxPaperPlane } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createComment } from "redux/postCommunity/postCommunity.slice";

type Props = {
  postId: string;
  avatar: string;
  reload: () => void;
  focus: boolean;
};

const InputComment = ({ postId, avatar, reload, focus }: Props) => {
  const dispatch = useDispatch<any>();
  const inputRef = useRef(null);
  focus && inputRef.current && (inputRef.current as HTMLInputElement).focus();

  const [inputValue, setInputValue] = useState<string>("");

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputValue === "") {
      toast("Bạn vui lòng nhập nội dung bài viết");
      return;
    }
    await dispatch(
      createComment({ postId: postId, content: inputValue }),
    ).unwrap();
    setInputValue("");
    reload();
  };

  setTimeout(() => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, 5000);

  // logs

  return (
    <form
      className="flex items-center gap-x-3"
      onSubmit={onFormSubmit}
    >
      {avatar ? (
        <img
          src={avatar}
          alt="user-avatar"
          className="h-8 w-8 rounded-full object-cover"
        />
      ) : (
        <RxAvatar
          size={32}
          className=""
        />
      )}

      <div className="flex flex-1 items-center rounded-md bg-gray-100 px-3 py-2">
        <input
          placeholder="Write a comment..."
          className="flex-1 bg-inherit"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          ref={inputRef}
        />
        <button type="submit">
          <RxPaperPlane
            size={16}
            className="cursor-pointer hover:text-sky-600"
          />
        </button>
      </div>
    </form>
  );
};

export default InputComment;
