import React, { useState } from "react";
import Comment from "./Comment";

type Props = {};

const Comments = ({ comments, reload }: any) => {
  return (
    <div className="comments space-y-4">
      {comments?.map((comment: any, index: any) => (
        <React.Fragment key={index}>
          <Comment
            data={comment}
            reload={reload}
            // setReload={setReload}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Comments;
