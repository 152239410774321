import { Breadcrumb, Modal, Pagination, Popover, Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  checkFacebookComment,
  createPaymentUser,
  deletegooglemapErrors,
  getPagingWorker,
  updateWorkerTaskToRedo,
  getErrorJobReport
} from 'api/task.api';
import ModelsuccessPay from 'components/manage/ModelSuccessPay';
import PrimaryButton from 'components/ui/button/PrimaryButtton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiFillDelete, AiFillEye } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { GoSearch } from 'react-icons/go';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { renderStatus } from '../../../util';
import { sendLogs } from "common/function";
import { GenderConst, TypeGroup, TypeAction } from "common/constant";
import ModalErrorJob from "components/manage/ModalErrorJob";

const ManageCommentFacebook = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [chooseTask, setChooseTask] = useState<any>();
  const [checkFollow, setCheckFollow] = useState<any>(null);
  const [body, setBody] = useState<any>({});
  const [modalPay, setModalPay] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  
  const [messError, setMessError] = useState('')

  const handleOkError = () => {
    setModalError(false)
  }

  const handleCancelError = () => {
    setModalError(false)
  }
  const handleOkPay = () => {
    setModalPay(false);
  };

  const handleCancelPay = () => {
    setModalPay(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setCheckFollow(null);
    setIsModalOpen(false);
  };
  
  const handleRedoOfTask = async () => {
    const obj = {
      userAccount : body?.customerId?.userName,
      customerId : body?.customerId?._id,
      _id : body?.channelId?._id,
      taskId: body?.task?._id
    }
    const createRedo:any = await updateWorkerTaskToRedo(obj)
    if (createRedo.statusCode === 1) {
      sendLogs(
        TypeGroup[9], //job facebook
        TypeAction[6], // redo
        createRedo
      );
      message.success("Yêu cầu làm lại thành công!");
    }
    getPagingWorkerWithFacebook()
    setIsModalOpen(false);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'customerId',
      key: 'customerId',
      render: (value) => (value.fullName ? value.fullName : value.userName),
    },
    {
      title: "Tên tài khoản Facebook",
      dataIndex: "channelId",
      key: "channelId",
      render: (value) => value.title,
    },
    {
      title: "URL của tài khoản",
      key: "channelId",
      dataIndex: "channelId",
      render: (value) => value.linkChannel,
    },
    {
      title: 'URL facebook cần comment',
      key: 'link',
      dataIndex: 'link',
      render: (value) => {
        return (
          <Popover
            content={value}
            title=''
          >
            {value.substring(0, 40)}...
          </Popover>
        );
      },
    },
    {
      title: 'Tiền thanh toán',
      key: 'money',
      dataIndex: 'money',
      render: (value) => `${value} credit`,
      sorter: (a, b) => a.money - b.money,
    },
    {
      title: 'Nội dung bình luận',
      key: 'comment',
      dataIndex: 'comment',
      render: (value) => value,
    },
    {
      title: 'Tình trạng',
      key: 'status',
      dataIndex: 'status',
      render: (value) => {
        if (value === 'approved') {
          return (
            <div className='text-center'>
              <span className='bg-green-500 text-white rounded-lg text-center py-1 px-2'>
                {renderStatus(value)}
              </span>
            </div>
          );
        }
        if (value === 'pending') {
          return (
            <div className='text-center'>
              <span className='bg-orange-500 text-white rounded-lg text-center py-1 px-2'>
                {renderStatus(value)}
              </span>
            </div>
          );
        }
        if (value === 'newRedo') {
          return (
            <div className='text-center'>
              <span className='bg-yellow-500 text-white rounded-lg text-center py-1 px-2'>
                {renderStatus(value)}
              </span>
            </div>
          );
        }
        if (value === 'error') {
          return (
            <div className='text-center'>
              <span className='bg-red-500 text-white rounded-lg text-center py-1 px-2'>
                {renderStatus(value)}
              </span>
            </div>
          );
        } else {
          return (
            <div className='text-center'>
              <span className='bg-red-500 text-white rounded-lg text-center py-1 px-2'>
                Chưa hoàn thành
              </span>
            </div>
          );
        }
      },
    },
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Ngày thanh toán',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => moment(value).add(7, 'days').format('DD/MM/YYYY'),
    },
    {
      title: 'Hoạt động',
      key: '',
      dataIndex: '',
      render: (value, record) => {
        return (
          <div className='flex'>
            {(() => {
              if (record.status === '') {
                return (
                  <div className='flex'>
                    <AiFillEye
                      onClick={() => checkFacebookComments(record)}
                      className='cursor-pointer'
                      size={20}
                    />
                    <AiFillDelete
                      onClick={() => {
                        deletegooglemapError(record);
                      }}
                      className='cursor-pointer ml-2'
                      size={20}
                    />
                  </div>
                );
              }
              if (record.status === 'approved') {
                return (
                  <div>
                    <AiFillEye
                      onClick={() => setModalPay(true)}
                      className='cursor-pointer'
                      size={20}
                    />
                  </div>
                );
              }
              if (record.status === 'pending') {
                return (
                  <div className='flex'>
                    <AiFillEye
                      onClick={() => checkFacebookComments(record)}
                      className='cursor-pointer'
                      size={20}
                    />
                  </div>
                );
              }
              if (record.status === 'error') {
                return (
                  <div className='flex'>
                    <AiFillEye
                      onClick={() => getErrorReport(record)}
                      className='cursor-pointer'
                      size={20}
                    />
                  </div>
                );
              }
            })()}
          </div>
        );
      },
    },
  ];
  const getErrorReport = async (val: any) => {
    setModalError(true)
    const getError = await getErrorJobReport({ workerId: val._id })
    setMessError(getError?.data?.errorReport)
  }
  const deletegooglemapError = async (data: any) => {
    const result: any = await deletegooglemapErrors(data?._id);
    if (result.uniqueCode === 1) {
      getPagingWorkerWithFacebook();
      message.success('Xóa thành công');
    }
    setIsModalDeleteOpen(true);
    setChooseTask(data);
  };

  const getPagingWorkerWithFacebook = async () => {
    const query = {
      type: '64dc43049b3bdde87a4f704e',
      search: search,
      pageIndex: pageIndex,
      pageSize: pageSize,
      typeTask: 2,
    };
    const result: any = await getPagingWorker(query);

    const newResult = result?.data.map((element: any, index: number) => {
      return {
        key: index,
        ...element,
      };
    });
    setData(newResult);
    setTotalPage(result?.counts);
  };

  useEffect(() => {
    getPagingWorkerWithFacebook();
  }, [pageSize, pageIndex]);

  const handleSearch = () => {
    getPagingWorkerWithFacebook();
  };

  const checkFacebookComments = async (data: any) => {
    setIsModalOpen(true);
    const body = {
      urlPost: data?.task?.link,
      comment: data.comment,
    };
    const result = await checkFacebookComment(body);

    setCheckFollow(result);
    setBody({
      ...data,
    });
  };

  const onClickPayAccount = async () => {
    const dataRef = {
      socialMedia: 'facebook',
      customerId: body.customerId?._id,
      taskId: body.task?._id,
      _id: body?.channelId?._id,
    };
    const result: any = await createPaymentUser(dataRef);
    if (result.statusCode === 1) {
      sendLogs(
        TypeGroup[9], //job facebook
        TypeAction[4], // approve
        result
      );
      message.success('Thanh toán thành công!');
      handleCancel();
      getPagingWorkerWithFacebook();
    }
  };

  const handleDeleteOk = async () => {
    setIsModalDeleteOpen(false);
    const result: any = await deletegooglemapErrors(chooseTask?._id);
    if (result.uniqueCode === 1) {
      message.success('Xóa nhiệm vụ thành công!');
      getPagingWorkerWithFacebook();
    }
    if (result.uniqueCode === 2) {
      message.success('Xóa nhiệm vụ thất bại!');
      getPagingWorkerWithFacebook();
    }
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteOpen(false);
  };

  return (
    <div
      className='p-4'
      id='manage'
    >
      <div className='mb-4'>
        <Breadcrumb
          items={[
            { title: 'Quản lý nhiệm vụ comment Facebook' },
            { title: 'Nhiệm vụ comment Facebook' },
          ]}
        ></Breadcrumb>
      </div>
      <div className='mb-4'>
        <div className='grid grid-cols-12 gap-x-2 gap-y-3 mb-3'>
          <div className='col-span-12 lg:col-span-7 flex sm:flex-nowrap flex-wrap gap-2'>
            <div className='flex sm:w-1/3 w-full rounded-md border border-gray-300 bg-white items-center py-1.5'>
              <input
                className='bg-white pl-3 w-full'
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              ></input>
              <GoSearch
                size={20}
                className='mx-3'
              />
            </div>

            <PrimaryButton
              className='w-full sm:w-1/3 lg:w-max !py-1.5 px-5'
              content='Tìm kiếm'
              onClick={handleSearch}
              icon={
                <GoSearch
                  size={20}
                  className='mr-1'
                />
              }
            ></PrimaryButton>
          </div>
        </div>
        <div className='p-4 rounded-lg border border-gray-300 bg-white'>
          <div className='overflow-x-scroll bg-white'>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
          <Pagination
            className='mt-3'
            current={pageIndex}
            total={totalPage}
            showSizeChanger={true}
            pageSizeOptions={[1, 10, 20, 50, 100]}
            onChange={(page, pageSize) => {
              setPageIndex(page !== 0 ? page : 1);
              setPageSize(pageSize);
            }}
          />
        </div>
      </div>
      <Modal
        title='Bảng thông tin tài khoản đã bình luận hay chưa'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        bodyStyle={{ height: '400px' }}
      >
        {checkFollow !== null ? (
          checkFollow?.comment ? (
            <div>
              <div className='block text-center text-2xl text-lime-700 font-bold h-80'>
                <div className='block h-16 m-5'>
                  {' '}
                  Tài khoản này đã thực hiện bình luận
                </div>
                <div className='flex justify-center '>
                  <BsCheckCircleFill size={70} />{' '}
                </div>
              </div>
              <div className='flex items-center justify-end'>
                <button
                  onClick={onClickPayAccount}
                  className='w-auto py-2 px-5 bg-green-600 rounded-lg mr-4 text-white text-lg hover:bg-green-700'
                >
                  Thanh toán
                </button>
                <button
                  onClick={handleCancel}
                  className='w-auto py-2 px-5 bg-red-600 rounded-lg mr-4 text-white text-lg hover:bg-red-700'
                >
                  Thoát
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className='block text-center text-2xl text-red-700 font-bold h-80'>
                <div className='block h-16 m-5'>
                  {' '}
                  Tài khoản này chưa thực hiện bình luận
                </div>
                <div className='flex justify-center '>
                  <IoCloseCircleSharp size={70} />{' '}
                </div>
              </div>
              <div className='flex items-center justify-end'>
                <button
                  onClick={handleRedoOfTask}
                  className="w-auto py-2 px-5 bg-green-600 rounded-lg mr-4 text-white text-lg hover:bg-green-700"
                >
                  Yêu cầu làm lại
                </button>
                <button
                  onClick={handleCancel}
                  className='w-auto py-2 px-5 bg-red-600 rounded-lg mr-4 text-white text-lg hover:bg-red-700'
                >
                  Thoát
                </button>
              </div>
            </div>
          )
        ) : (
          <div className='flex items-center justify-center h-full'>
            <img
              className='w-[200px] h-[200px]'
              src='/source.gif'
              alt=''
            />
          </div>
        )}
      </Modal>
      <Modal
        title={<div>{`Bạn muốn xóa nhiệm vụ ?`}</div>}
        open={isModalDeleteOpen}
        onOk={handleDeleteOk}
        okText='Có'
        onCancel={handleDeleteCancel}
        cancelText='Không'
      ></Modal>
      <ModelsuccessPay
        isModel={modalPay}
        handleOk={handleOkPay}
        handleCancel={handleCancelPay}
      />
      <ModalErrorJob
        isModel={modalError}
        handleOk={handleOkError}
        handleCancel={handleCancelError}
        error={messError}
      />
    </div>
  );
};

export default ManageCommentFacebook;
