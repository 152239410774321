import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import PrimaryButton from "components/ui/button/PrimaryButtton";
import { Breadcrumb, DatePicker, Select, ConfigProvider } from "antd";
import { BsCheck2All } from "react-icons/bs";
import ListLogsTable from "./components/ListLogsTable";
import { logAction } from "redux/logs-manager/logs-manager.slice";
import { Action, Group, TypeGroup } from "common/constant";
import viVN from "antd/locale/vi_VN";
type SearchAction = {
  content: string;
  startDate: string;
  endDate: string;
  actions: number[];
  groups: number[];
};
function LogsManager() {
  const dispatch = useDispatch<any>();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { RangePicker } = DatePicker;
  const [search, setSearch] = useState<SearchAction>({
    content: "",
    startDate: "",
    endDate: "",
    actions: [],
    groups: [],
  });
  const [selectedId, setSelectedId] = useState<any>([]);
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    dispatch(
      logAction.getListLog({
        pageIndex: pageIndex,
        pageSize: pageSize,
        content: search.content ?? "",
        startDate: search.startDate ?? "",
        endDate: search.endDate ?? "",
        groups: search.groups,
        actions: search.actions,
      }),
    );
  };
  const onSelectedDay = (value: any, dateString: [string, string] | string) => {
    setSearch({ ...search, startDate: dateString[0], endDate: dateString[1] });
  };
  useEffect(() => {
    dispatch(logAction.getListLog({}));
  }, []);
  useEffect(() => {
    handleSearch();
  }, [pageIndex, pageSize]);
  return (
    <div className="p-4">
      <div className="mb-4">
        <Breadcrumb items={[{ title: "Quản lý log" }]}></Breadcrumb>
      </div>
      <div className="mb-4">
        <h3 className="mb-4 font-semibold">Quản lý log</h3>
        <div className="mb-3 grid grid-cols-12 gap-x-2 gap-y-3">
          <div className="col-span-12 flex flex-wrap gap-2 sm:flex-nowrap lg:col-span-9">
            <div className="flex w-full items-center rounded-md border border-gray-300 bg-white py-1.5 sm:w-[15%] sm:min-w-[15%]">
              <input
                className="w-full bg-white pl-3  lg:col-span-5 "
                value={search.content}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch({ ...search, content: e.target.value })
                }
                onKeyDown={handleEnter}
              ></input>
              <GoSearch
                size={20}
                className="mx-3"
              />
            </div>
            <Select
              maxTagCount={1}
              id="log-select"
              mode="multiple"
              virtual={false}
              placeholder="Chọn Loại"
              className="lg:min-w-1/3 w-full sm:w-[25%] sm:min-w-[25%] lg:w-1/3 [&>div>div]:!flex-nowrap [&>div>div]:!overflow-hidden [&>div]:!py-1"
              allowClear
              options={Group?.map((value: string, index: number) => ({
                value: index,
                label: value,
              }))}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(values) => {
                setSearch({ ...search, groups: values });
              }}
            />
            <Select
              maxTagCount={1}
              id="log-select"
              mode="multiple"
              placeholder="Chọn Hành Động"
              className="lg:min-w-1/3 w-full sm:w-[25%] sm:min-w-[25%] lg:w-1/3 [&>div>div]:!flex-nowrap [&>div>div]:!overflow-hidden [&>div]:!py-1"
              allowClear
              options={Action?.map((value: string, index: number) => ({
                value: index,
                label: value,
              }))}
              filterOption={(input, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(values) => {
                setSearch({ ...search, actions: values });
              }}
            />
            <ConfigProvider locale={viVN}>
              <RangePicker
                allowClear
                onChange={onSelectedDay}
                className="w-full sm:w-[18%] sm:min-w-[18%] lg:w-1/3 [&>div>div]:!flex-nowrap [&>div>div]:!overflow-hidden [&>div]:!py-1"
                format={"DD/MM/YYYY"}
              />
            </ConfigProvider>
            <div className="col-span-12 flex flex-wrap justify-between gap-2 sm:flex-nowrap lg:col-span-5">
              <PrimaryButton
                className="w-full !py-1.5 px-5 sm:w-2/3 lg:w-max"
                content="Tìm kiếm"
                onClick={handleSearch}
                icon={
                  <GoSearch
                    size={20}
                    className="mr-1"
                  />
                }
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      <ListLogsTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        handleSearch={handleSearch}
        setSelectedId={setSelectedId}
      />
    </div>
  );
}
export default LogsManager;
